import * as ActionTypes from '../constants/ActionTypes';
import { push } from 'connected-react-router';
import Network from '../lib/network';

import { 
  clearEntity,
  receiveEntity,
  receiveEntities 
} from './entities';

const api = Network();

const path = resource => {
  if (!resource) resource = '';
  return { resource: 'locations/' + resource };
}

export const createLocation = location => {
  return (dispatch, getState) => {
    return api.post(path(), location)
      .then(res => dispatch(receiveEntity(res)));
  }
}

export const updateLocation = location => {
  return (dispatch, getState) => {
    return api.edit(path(location.id), location)
      .then(res => dispatch(receiveEntity(res)));
  }
}

export const getLocations = () => {
  return (dispatch, getState) => {
    return api.get(path())
      .then(res => dispatch(receiveEntities(res)));
  }
}
