import { isImmutable, Record } from 'immutable';
import AddressRecord from './address';
import PhoneRecord from './phone';

export default class UserRecord extends Record({
    id: null,
    home_id: null,
    organization_id: null,
    first_name: '' ,
    last_name: '',
    email: null,
    roles: [],
    vehicle_preference: null,
    availability: ['sunday', 'monday', 'tuesday',  'wednesday', 'thursday', 'friday', 'saturday'],
    email_digest: true,
    email_reminder: true,
    is_logged_in: false,
    salesforce_id: '',
    active: true,
    note: ''
  }) {
    get fullName() {
      return this.first_name + ' ' + this.last_name;
    }

    available(day) {
      return this.availability.includes(day);
    }

    role(type) {
      let roles = this.roles;
      if (isImmutable(this.roles)) roles = this.roles.toJS();
      if (!Array.isArray(roles)) return roles.search(type) >= 0;
      return roles.findIndex(r => r === type.toLowerCase()) >= 0;
    }

    location(locations) {
      let l = locations.get(this.home_id);

      if (!l) l = new AddressRecord({ organization_id: this.organization_id });

      return l;
    }

    phone(phones, primary = true) {
      let p = phones.filter(ph => ph.user_id === this.id);
      const d = new PhoneRecord({ label: 'Primary', primary: primary });

      if (p.count() > 0) {
        if (primary) return p.find(ph => ph.primary) || d;
        if (!primary) return p.find(ph => !ph.primary) || new PhoneRecord({ label: 'Alternate', primary: false });
      } else {
        return d;
      }
    }

    mapped(field, state) {
      switch(field) {
        case 'address1':
          return this.location(state.get('location')).address1;
          break;
        case 'city':
          return this.location(state.get('location')).city;
          break;
        case 'state':
          return this.location(state.get('location')).state;
          break;
        case 'postal_code':
          return this.location(state.get('location')).postal_code;
          break;
        default:
          return this[field];
      }
    }
  }
