import React from 'react';
import { connect } from 'react-redux';
import { validateAuth } from '../actions/users';

class ValidateAuth extends React.Component {
  state = {
    invalid: false,
    error: ''
  }

  componentDidMount() {
    if (!this.props.match.params || !this.props.match.params.token || this.props.match.params.token === '') {
      this.setState({ invalid: true });
    } else {
      this.setState({ invalid: false });
      this.props.validateAuth(this.props.match.params.token)
        .catch(err => this.setState({ invalid: true, error: err }));
    }
  }

  render() {
    return <section className="hero is-large">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column is-4" />
            <div className="column is-4">
              {this.state.invalid && <div className="notification is-warning">There was a problem logging in: {this.state.error.toString()}</div>}
            </div>
            <div className="column is-4" />
          </div>
        </div>
      </div>
    </section>
  }
}

function mapStateToProps(state) {
  return {};
}

export const ValidateAuthContainer = connect(
  mapStateToProps,
  { validateAuth: validateAuth }
)(ValidateAuth);
