import React from 'react';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';

class Options extends React.Component {
  state = {
    toggled: null
  }

  handleClickOutside = () => {
    this.toggle();
  }

  toggle = id => {
    this.setState({ toggled: id === this.state.toggled ? null : id });
  }

  render() {
    const r = this.props.ride;
    const o = this.props.entities.get('organization').get(r.organization_id);

    return <div className={classNames('dropdown', {
      'is-active': r.id === this.state.toggled
    })}>
      {(!r.driver_id || r.driver_id === this.props.users.get('user').id) && <div className="dropdown-trigger">
        {this.state.toggled !== r.id && <a onClick={() => this.toggle(r.id)}><i className="fa fa-ellipsis-h"></i></a>}
        {this.state.toggled === r.id && <a onClick={() => this.toggle(r.id)}><i className="fa fa-ellipsis-v"></i></a>}
      </div>}
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          {!r.driver_id && <a className="dropdown-item" onClick={() => this.props.claim(r)}><i className="fa fa-check-double"></i> Claim</a>}
          {r.driver_id === this.props.users.get('user').id && <a className="dropdown-item" onClick={() => this.props.cancel(r)}><i className="fa fa-times"></i> Cancel</a>}
          {r.driver_id === this.props.users.get('user').id && <hr className="dropdown-divider" />}
        </div>
      </div>
    </div> 
  }
}

export default onClickOutside(Options);
