import { Record } from 'immutable';
import moment from 'moment';

export default class RideRecord extends Record({
    id: null,
    appointment: null,
    appointment_length: 0,
    canceled: false,
    when: new Date(),
    vehicle: 'personal',
    passenger_id: null,
    driver_id: null,
    dropoff_id: null,
    pickup_id: null,
    organization_id: null,
    note: '',
    category: ''
  }) {
    get cancelable() {
      return moment(this.when).isSameOrBefore(moment().subtract(1, 'days')) || moment(this.when).isAfter(moment().add(1, 'days'));
    }

    get vehiclePref() {
      if (this.vehicle === 'both') return 'either';
      return this.vehicle;
    }

    mapped(field, state) {
      switch(field) {
        case 'appointment':
        case 'when':
          return moment(this[field]);
          break;
        case 'driver_id':
        case 'passenger_id':
          return state.get('user').get(this[field]).fullName;
          break;
        case 'dropoff_id':
        case 'pickup_id':
          return state.get('location').get(this[field]) && state.get('location').get(this[field]).display;
          break;
        case 'pickup_time':
          return moment(this['when']).format('h:mma');
          break;
        case 'vehicle':
          if (this[field] === 'provided') {
            return 'FW';
          } 
        default:
          return this[field];
      }
    }
  }
