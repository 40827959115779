import React from 'react';
import PropTypes from 'prop-types';
import onClickOutside from "react-onclickoutside";

const suggestionContainerStyles = {
  position: 'absolute', 
  zIndex: '2000', 
  backgroundColor: '#FFFFFF',
  border: '1px solid #CCCCCC'
}

const suggestionStyles = {
  cursor: 'pointer', 
  borderBottom: '1px solid #CCCCCC', 
  padding: '5px'
}

class TypeAhead extends React.Component {
  state = {
    name: this.props.name || 'typeahead',
    className: this.props.className || '',
    suggestions: this.props.suggestions || [],
    matchedSuggestions: [],
    placeholder: this.props.placeholder || '',
    value: this.props.value.trim() !== '' ? this.props.value : ''
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.value !== this.props.value) && (this.props.value !== prevProps.value)) {
      this.setState({ value: this.props.value });
    }
  }

  handleClickOutside = e => {
    this.setState({ matchedSuggestions: [] });
  };

  onChange = e => {
    this.setState({ value: e.target.value });

    if (!this.props.matchSuggestions) return e;

    this.setState({ matchedSuggestions: this.props.matchSuggestions(e.target.value, this.state.suggestions) });
  }

  select = value => {
    this.setState({ value: this.props.suggestion(value, true), matchedSuggestions: [] });

    if (this.props.onChange) this.props.onChange(this.state.name, value);
  }

  suggestion = value => {
    return (this.props.suggestion && this.props.suggestion(value) || value);
  }

  render() {
    return <span style={{ zIndex: 99999999 }}>
      <input type="text" 
        autoComplete="new-password"
        onChange={this.onChange}
        className={this.state.className} 
        placeholder={this.state.placeholder}
        value={this.state.value}
        name={this.state.name} />
      {this.state.matchedSuggestions.length > 0 &&
        <div style={this.props.suggestionContainerStyles || suggestionContainerStyles}>
          <ul>
            {this.state.matchedSuggestions.map((s, i) => <li style={this.props.suggestionStyles || suggestionStyles} onClick={() => this.select(s)} key={i}>{this.suggestion(s)}</li>)}
          </ul>
        </div>}
    </span>
  }
}

export default onClickOutside(TypeAhead);

TypeAhead.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  suggestions: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  suggestion: PropTypes.func,
  matchSuggestions: PropTypes.func
};
