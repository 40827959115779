import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserRecord from '../records/user';

function useRide() {
  const users = useSelector(state => state.entities.get('user'));

  const helpers = {
    driver: ride => users.get(ride.driver_id) || new UserRecord(),
    passenger: ride => users.get(ride.passenger_id) || new UserRecord()
  }

  return helpers;
}

export default useRide;
