import React, { useEffect, useState } from 'react';
import { List } from 'immutable';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { createUser, deleteUser, updateCurrentUser, updateUser } from '../../actions/users';
import { createPhone, deletePhone, updatePhone } from '../../actions/phones';
import { createLocation, updateLocation } from '../../actions/locations';
import UserRecord from '../../records/user';
import NewUser from './NewUser';
import EditUser from './EditUser';
import Desktop from './users/Desktop';

export default function Users({ match }) {
  const entities = useSelector(state => state.entities);
  const users = useSelector(state => state.entities.get('user'));
  const user = useSelector(state => state.users.get('user'));
  const locations = useSelector(state => state.entities.get('location'));
  const phones = useSelector(state => state.entities.get('phone_number'));

  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState({ user: false });
  const [usersState, setUsersState] = useState(new List());

  const type = match.params.type;

  useEffect(() => {
    setup();
  }, [type, users]);

  const setup = () => {
    let data = users
      .filter(u => u.roles.includes(type))
      .sortBy(u => u.first_name)
      .valueSeq();

    setUsersState(data);
  }

  return (
    <div>
      <Desktop
        type={type}
        setAdding={setAdding}
        setEditing={setEditing}
        users={usersState}
      />

      {editing.user && <EditUser
        user={editing.user}
        dismiss={setEditing.bind(this, false)}
      />}

      {adding && <NewUser
        user={new UserRecord({
          roles: [type],
          organization_id: user.organization_id
        })}
        callback={() => false}
        dismiss={setAdding.bind(this)} />}
    </div>
  );
}
