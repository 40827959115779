export const VERSION = '1.0.0';

export const platform = () => {
  if (window.location.hostname === 'localhost' || !window.location.hostname) {
    return 'development';
  }

  return 'production';
}

export const server = () => {
  if (window.location.hostname === 'localhost' || !window.location.hostname) {
    return 'http://localhost:3000';
  }

  return 'https://ridesnridesnrides.fly.dev';
}

export const dateFormat = 'YYYY-MM-DD';

export const rideConfig = {
  PASS: 'passenger',
  DRIV: 'driver',
  VEHICLE: {
    PL: 'personal',
    PD: 'provided'
  }
};

export const colors1 = {
  1: 'rgb(248, 249, 251)',
  2: 'rgb(225, 236, 247)',
  3: 'rgb(174, 203, 235)',
  4: 'rgb(131, 176, 225)',
  5: 'rgb(113, 165, 222)',
  6: '#ffffff',
  7: '#efefef'
}

export const colors2 = {
  1: 'rgb(238, 238, 238)',
  2: 'rgb(221, 221, 221)',
  3: 'rgb(204, 204, 204)',
  4: 'rgb(187, 187, 187)',
  5: 'rgb(170, 170, 170)',
  6: '#ffffff',
  7: '#efefef'
}

export const colors3 = {
  1: 'rgb(246, 242, 240)',
  2: 'rgb(243, 231, 228)',
  3: 'rgb(231, 209, 201)',
  4: 'rgb(241, 231, 221)',
  5: 'rgb(208, 180, 159)',
  6: '#ffffff',
  7: '#efefef'
}

export const colors4 = {
  1: 'rgb(255, 255, 255)',
  2: 'rgb(254, 240, 239)',
  3: 'rgb(253, 225, 222)',
  4: 'rgb(251, 195, 188)',
  5: 'rgb(228, 177, 171)',
  6: '#ffffff',
  7: '#efefef'
}

export const colors5 = {
  1: 'rgb(255, 254, 248)',
  2: 'rgb(255, 252, 235)',
  3: 'rgb(255, 249, 214)',
  4: 'rgb(255, 246, 194)',
  5: 'rgb(255, 243, 173)',
  6: '#ffffff',
  7: '#efefef'
}

export const colors6 = {
  1: 'rgb(196, 206, 161)',
  2: 'rgb(217, 224, 163)',
  3: 'rgb(253, 242, 176)',
  4: 'rgb(243, 209, 124)',
  5: 'rgb(207, 153, 99)',
  6: '#ffffff',
  7: '#efefef'
}

export const colors7 = {
  1: 'rgb(246, 189, 96)',
  2: 'rgb(247, 237, 226)',
  3: 'rgb(245, 202, 195)',
  4: 'rgb(132, 165, 157)',
  5: 'rgb(242, 132, 130)',
  6: '#ffffff',
  7: '#efefef'
}

export const colors8 = {
  1: 'rgb(247, 178, 103)',
  2: 'rgb(247, 157, 101)',
  3: 'rgb(244, 132, 95)',
  4: 'rgb(242, 112, 89)',
  5: 'rgb(242, 92, 84)',
  6: '#ffffff',
  7: '#efefef'
}
