import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';

export default function Desktop({ type, setAdding, setEditing, users }) {
  const phones = useSelector(state => state.entities.get('phone_number'));
  const locations = useSelector(state => state.entities.get('location'));
  const user = useSelector(state => state.users.get('user'));
  const org = useSelector(state => state.entities.get('organization').get(user.organization_id));

  const defaultMaterialTheme = createTheme();

  const email = () => {
    const drivers = users
      .filter(d => d.role('driver'))
      .map(d => d.email);

    window.open('mailto: ' + user.email + '?subject=' + org.name + ' Driver Email&bcc=' + drivers.join(','), '_blank');
  }

  const title = () => {
    let role;

    switch(type) {
      case 'passenger':
        role = 'Passengers';
        break;
      case 'driver':
        role = 'Drivers';
        break;
      default:
        role = 'Admins';
    }

    return <>
      {role} <a onClick={setAdding} style={{ cursor: 'pointer' }}><i className="fa fa-plus"></i></a> <a onClick={email}><i className="fa fa-envelope"></i></a>
      </>;
  }

  const data = users.map(u => ({
    user: u,
    first_name: u.first_name,
    last_name: u.last_name,
    email: u.email,
    primary_phone: u.phone(phones).number,
    alternate_phone: u.phone(phones, false).number,
    address1: u.location(locations).address1,
    city: u.location(locations).city,
    state: u.location(locations).state,
    postal_code: u.location(locations).postal_code
  }))
  .toArray();

  const columns = [{
    title: 'First Name',
    field: 'first_name'
  }, {
    title: 'Last Name',
    field: 'last_name'
  }, {
    title: 'Email',
    field: 'email'
  }, {
    title: 'Primary Phone',
    field: 'primary_phone'
  }, {
    title: 'Alternate Phone',
    field: 'alternate_phone'
  }, {
    title: 'Address',
    field: 'address1'
  }, {
    title: 'City',
    field: 'city'
  }, {
    title: 'State',
    field: 'state'
  }, {
    title: 'Zip',
    field: 'postal_code'
  }];

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MaterialTable
        title={title()}
        options={{
          paging: false,
          padding: 'dense',
          rowStyle: data => {
            return {
              fontSize: '.8em'
            }
          }
        }}
        data={data}
        columns={columns}
        onRowClick={(e, data) => setEditing({ user: data.user })}
      >
      </MaterialTable>
    </ThemeProvider>
  );
}
