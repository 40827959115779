import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import locations from './locations';
import users from './users';
import entities from './entities'
import reports from './reports';
import rides from './rides'

const createRootReducer = (history) => combineReducers({ 
  router: connectRouter(history),
  locations,
  users,
  entities,
  reports,
  rides
});

export default createRootReducer;
