import React from 'react';

export default class Register extends React.Component {
  render() {
    return <section className="hero is-info is-medium is-bold">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title">Driver registration is coming soon!</h1>
          <p className="subtitle">In the meantime send us an email with your info and we'll get back to you.</p>
          <a href="mailto: freewheelingroup@gmail.com" className="button is-large is-primary">Send Request</a>
        </div>
      </div>
    </section>
  }
}
