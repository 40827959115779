import * as ActionTypes from '../constants/ActionTypes';
import { push } from 'connected-react-router';
import Network from '../lib/network';
import Cookies from 'universal-cookie';
import { platform } from '../constants/SiteVariables';

import {
  clearEntity,
  receiveEntity,
  receiveEntities
} from './entities';

import {
  getLocations
} from './locations';

import {
  getPhones
} from './phones';

import {
  getOrganization
} from './organizations';

const api = Network();
const cookie = new Cookies();

const path = resource => {
  if (!resource) resource = '';
  return { resource: 'users/' + resource };
}

export const loading = bool => ({
  type: ActionTypes.LOADING,
  payload: bool
});

export const requestAuth = email => {
  return (dispatch, getState) => {
    return api.post({
      resource: 'auth'
    }, {
      email: email.toLowerCase()
    });
  }
}

export const validateAuth = token => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    return api.get({
      resource: 'auth/' + token
    })
    .then(res => {
      cookie.set('user', {
        id: res.data.attributes.id,
        token: res.data.id
      }, { path: '/', domain: platform() === 'production' ? '.ridesnridesnrides.com' : 'localhost'});
    })
    .then(() => dispatch(isUserLoggedIn()))
    .then(() => dispatch(push('/')));
  }
}

export const createUser = user => {
  return (dispatch, getState) => {
    return api.post(path(), user)
      .then(res => dispatch(receiveEntity(res)));
  }
}

export const updateUser = user => {
  return (dispatch, getState) => {
    return api.edit(path(user.id), user)
      .then(res => res.data.attributes.active ? dispatch(receiveEntity(res)) : dispatch(clearEntity(res.data, 'user')));
  }
}

export const deleteUser = user => {
  return (dispatch, getState) => {
    return api.delete(path(user.id))
      .then(res => dispatch(clearEntity(user, 'user')));
  }
}

// expects a modified copy of the logged in user record
export const updateCurrentUser = user => ({
  type: ActionTypes.UPDATE_CURRENT_USER,
  payload: user
});

export const getUsers = () => {
  return (dispatch, getState) => {
    return api.get(path())
      .then(res => dispatch(receiveEntities(res)));
  }
}

export const isUserLoggedIn = () => {
  let user;

  return (dispatch, getState) => {
    dispatch(loading(true));
    let savedUser = cookie.get('user');

    if (savedUser && savedUser.id) {
      return api.get(path(savedUser.id))
        .then(res => {
          if (!res.success && res.message) {
            dispatch(logout);
            dispatch(loading(false));
            dispatch(push('/'));
          }

          user = { data: Object.assign({ id: res.data.id }, res.data.attributes) };

          dispatch({
            type: ActionTypes.LOGIN,
            payload: user
          });

          dispatch(receiveEntity(res));
          return res.data.attributes.organization_id;
        })
        .then(res => res && dispatch(getOrganization(res)))
        .then(() => dispatch(getUsers()))
        .then(() => dispatch(getLocations()))
        .then(() => dispatch(getPhones(getState().entities.get('user').keySeq().toArray())))
        .then(() => dispatch(loading(false)))
        .catch(err => dispatch(logout));
    } else {
      return new Promise((resolve, reject) => resolve(dispatch(loading(false))));
    }
  }
}

export const logout = () => {
  return (dispatch, getState) => {
    cookie.remove('user', { path: '/', domain: platform() === 'production' ? '.freewheelinvt.com' : 'localhost'});

    dispatch({
      type: ActionTypes.LOGOUT,
      payload: 'user'
    });

    dispatch(push('/'));
  }
}

export const ping = () => {
  return (dispatch, getState) => {
    return api.get({
      resource: 'auth/ping'
    });
  }
}
