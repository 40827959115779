import { fromJS } from 'immutable';
import * as ActionTypes from '../constants/ActionTypes';
import { colors1 } from '../constants/SiteVariables';

const ridesState = fromJS({
  loading: true,
  canceled: false,
  search: {
    term: '',
    start: null,
    end: null
  },
  colors: colors1
});

function setState(state, newState) {
  return state.merge(newState);
}

export default function rides(state = ridesState, action) {
  switch(action.type) {
    case ActionTypes.FILTER_RIDES:
      return setState(state, state
        .setIn(['search', 'start'], action.payload.start)
        .setIn(['search', 'end'], action.payload.end)
      );

    case ActionTypes.FILTER_SEARCH:
      return setState(state, state.setIn(['search', 'term'], action.payload));

    case ActionTypes.LOADING_RIDES:
      return setState(state, state.set('loading', action.payload));

    case ActionTypes.TOGGLE_CANCELED:
      return setState(state, state.set('canceled', !state.get('canceled')));

    case ActionTypes.SET_COLORS:
      return setState(state, state.set('colors', fromJS(action.payload)));

    default:
      return state;
  }
}
