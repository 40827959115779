import Network from '../lib/network';

import { 
  clearEntity,
  receiveEntity,
  receiveEntities 
} from './entities';

const api = Network();

const path = resource => {
  if (!resource) resource = '';
  return { resource: 'phone_numbers/' + resource };
}

export const createPhone = phone => {
  return (dispatch, getState) => {
    return api.post(path(), phone)
      .then(res => dispatch(receiveEntity(res)));
  }
}

export const updatePhone = phone => {
  return (dispatch, getState) => {
    if (!phone.id) {
      return dispatch(createPhone(phone));
    }

    return api.edit(path(phone.id), phone)
      .then(res => dispatch(receiveEntity(res)));
  }
}

export const deletePhone = phone => {
  return (dispatch, getState) => {
    return api.delete(path(phone.id))
      .then(res => dispatch(clearEntity('phone_number', phone.id)));
  }
}

export const getPhones = filter => {
  if (filter) filter = '?user_ids=' + filter

  return (dispatch, getState) => {
    return api.get(path(filter))
      .then(res => dispatch(receiveEntities(res)));
  }
}
