import { createBrowserHistory } from 'history';
import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import ReduxThunk from 'redux-thunk';
import createRootReducer from './reducers';

export const history = createBrowserHistory();
const composeEnhancers = (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const configureStore = () => {
	const s = createStore(
		createRootReducer(history),
		composeEnhancers(
			applyMiddleware(
				routerMiddleware(history),
				ReduxThunk
			)
		)
	);

  if (module.hot && process.env.NODE_ENV !== 'production') {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept(createRootReducer(history), () => {
      const nextRootReducer = createRootReducer(history);
      s.replaceReducer(nextRootReducer);
    })
  }

  return s;
}

export const store = configureStore();
