import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';
import { colors1, colors2, colors3, colors4, colors5, colors6, colors7, colors8 } from '../../../constants/SiteVariables';
import useRide from '../../../hooks/useRide';
import { time_convert } from '../../../lib/utils';
import Color from '../../admin/schedule/Color';

export default function Desktop({ rides, claim, cancel }) {
  const rideHelpers = useRide();
  const locations = useSelector(state => state.entities.get('location'));
  const colors = useSelector(state => state.rides.get('colors'));
  const user = useSelector(state => state.users.get('user'));
  const phones = useSelector(state => state.entities.get('phone_number'));
  const org = useSelector(state => state.entities
    .get('organization')
    .get(user.organization_id));

  const [toggleColors, setToggleColors] = useState(false);

  const defaultMaterialTheme = createTheme();

  const showClaimOptions = data => {
    const ride = data.ride
    const driver = data.driver;
    
    let label = 'Claim';
    let icon = 'fa-check-double';
    
    if (ride.driver_id && ride.driver_id !== user.id) {
      return 'Claimed by: ' + driver.fullName
    }

    if (ride.driver_id && ride.driver_id === user.id) {
      label = 'Un-Claim';
      icon = 'fa-times';
    }

    return <button className="button is-small is-info" onClick={() => claim(ride)}>
        <span className="icon">
          <i className={'fa ' + icon}></i>
        </span>
        <span>{label}</span>
      </button>
  }

  const data = rides
    .map(r => ({
      ride: r,
      when: moment(r.when).toDate(),
      passenger: rideHelpers.passenger(r).fullName,
      address: locations.get(r.pickup_id) && locations.get(r.pickup_id).display,
      pickup_time: moment(r.when).format('h:mma'),
      appointment_length: time_convert(r.appointment_length),
      dropoff: locations.get(r.dropoff_id) && locations.get(r.dropoff_id).display,
      vehicle: r.vehicle === 'provided' ? org.prefix : r.vehiclePref,
      driver: rideHelpers.driver(r),
      category: r.category,
      note: r.note,
      passenger_note: rideHelpers.passenger(r).note,
      primary_phone: rideHelpers.passenger(r).phone(phones).number,
      alternate_phone: rideHelpers.passenger(r).phone(phones, false).number
    }))
    .sortBy(ride => ride.when)
    .toArray();

  const columns = [{
      title: 'Date',
      field: 'when',
      type: 'datetime',
      render: data => moment(data.when).format('ddd, MMM Do, YYYY')
    }, {
      title: 'Rider',
      field: 'passenger'
    }, {
      title: 'Driver',
      field: 'driver',
      render: data => showClaimOptions(data)
    }, {
      title: 'Address',
      field: 'address'
    }, {
      title: 'Pickup',
      field: 'pickup_time'
    }, {
      title: 'Time',
      field: 'appointment_length'
    }, {
      title: 'Droppoff',
      field: 'dropoff'
    }, {
      title: 'Vehicle',
      field: 'vehicle'
    }];

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MaterialTable
        title={<div>
          Schedule
          &nbsp;
          {!toggleColors && <span style={{ cursor: 'pointer', border: '1px solid ' + colors2[3] }} onClick={() => setToggleColors(!toggleColors)}>
            <span style={{ background: colors1[5] }}>&nbsp;</span>
            <span style={{ background: colors2[5] }}>&nbsp;</span>
            <span style={{ background: colors3[5] }}>&nbsp;</span>
            <span style={{ background: colors4[5] }}>&nbsp;</span>
            <span style={{ background: colors5[5] }}>&nbsp;</span>
            <span style={{ background: colors6[5] }}>&nbsp;</span>
            <span style={{ background: colors7[5] }}>&nbsp;</span>
            <span style={{ background: colors8[5] }}>&nbsp;</span>
          </span>}
          {toggleColors && <span style={{ cursor: 'pointer', color: colors2[5] }} onClick={() => setToggleColors(!toggleColors)}><i className="fa fa-times"></i>&nbsp;&nbsp;</span>}
          {toggleColors && <>
            <Color color={colors1} />
            <Color color={colors2} />
            <Color color={colors3} />
            <Color color={colors4} />
            <Color color={colors5} />
            <Color color={colors6} />
            <Color color={colors7} />
            <Color color={colors8} />
          </>}
        </div>}
        options={{
          paging: false,
          padding: 'dense',
          rowStyle: data => {
            return {
              background: colors.get(moment(data.ride.when).day().toString()),
              fontSize: '.8em'
            }
          }
        }}
        data={data}
        columns={columns}
        detailPanel={data => {
          return <div style={{ padding: '20px', background: colors.get(moment(data.ride.when).day().toString()) }}>
            <div className="columns">
              <div className="column is-one-fifth"></div>
              <div className="column"><strong>Note:</strong><br/>{data.note}</div>
              <div className="column"><strong>Passenger Note:</strong><br/>{data.passenger_note}</div>
              <div className="column is-one-fifth"></div>
            </div>
            <div className="columns">
              <div className="column is-one-fifth"></div>
              <div className="column">
                <div><strong>Category:</strong><br/>{data.category || 'NA'}</div>
              </div>
              <div className="column">
                <strong>Phone:</strong><br/>{data.primary_phone}<br/>
                <strong>Alt Phone:</strong><br/>{data.alternate_phone}
              </div>
              <div className="column is-one-fifth"></div>
            </div>

            <div className="has-text-centered">
              {showClaimOptions(data)} 
            </div>
          </div>
        }}
      />
    </ThemeProvider>
  );
}
