import { fromJS, OrderedMap } from 'immutable';
import * as ActionTypes from '../constants/ActionTypes';
import Address from '../records/address';
import User from '../records/user';
import Ride from '../records/ride';
import Phone from '../records/phone';
import Organization from '../records/organization';

const models = {
  location: Address,
  phone_number: Phone,
  organization: Organization,
  user: User,
  ride: Ride
}

export const entitiesState = fromJS(models).map(m => new OrderedMap());

function setState(state, newState) {
  return state.merge(newState);
}

export default function entities(state = entitiesState, action) {
  switch(action.type) {
    case ActionTypes.CLEAR_ENTITIES:
      if (action.payload) {
        return setState(state, state.set(action.payload, entitiesState.get(action.payload)));
      }
      return entitiesState;

    case ActionTypes.CLEAR_ENTITY:
      if (!action.payload) return state;
      return setState(state, state.deleteIn([action.entity ? action.entity : action.payload.type, action.payload.id]));

    case ActionTypes.RECEIVE_ENTITIES:
      if (action.payload.data && action.payload.data.length < 1) return state;

      action.payload.data.map(resource => state = setState(state, state
        .setIn([resource.type, resource.id], new models[resource.type](fromJS({ ...{ id: resource.id }, ...resource.attributes })))));

      return state;

    case ActionTypes.RECEIVE_ENTITY:
      return setState(state, state
        .setIn([action.payload.data.type, action.payload.data.id], new models[action.payload.data.type](fromJS({ ...{ id: action.payload.data.id }, ...action.payload.data.attributes }))));

    default:
      return state;
  }
}
