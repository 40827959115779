import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ping, requestAuth } from '../actions/users';

const emailValidation = (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export default function RequestAuth() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [valid, setValid] = useState(true);
  const [requested, setRequested] = useState(false);

  useEffect(() => {
    dispatch(ping());
  }, []);
  
  const onChange = e => setEmail(e.target.value);

  const validate = () => emailValidation.test(email);

  const reValidate = e => {
    if (!valid && validate()) {
      setValid(true);
    }
    if (e.key === 'Enter') {
      dispatch(requestAuth(email));
    }
  }

  const doLogin = () => {
    if (validate()) {
      dispatch(requestAuth(email));
      setRequested(true);
    } else {
      setValid(false);
      setRequested(false);
    }
  }

  return <div className="hero is-medium">
    <div className="hero-body">
      <div className="container has-text-centered">
        <span className="icon is-large">
          <i className="fa fa-2x fa-car"></i>&nbsp;
          <i className="fa fa-3x fa-car"></i>&nbsp;
          <i className="fa fa-2x fa-car"></i>
        </span>
        <h1 className="title">Ride n' Rides n' Rides</h1>
        <h1 className="subtitle">Ride share scheduling and administration made simple</h1>
        <div className="columns">
          <div className="column is-one-third"></div>
          <div className="column is-one-third">
          {requested && <div className="notification is-success">
             Please check your email for instructions to login.
           </div>}
           <form name="login-email">
            {!valid && <div className="notification is-danger">Invalid email address</div>}
              <div className="field has-addons">
                <div className="control is-expanded">
                  <input type="email"
                    autoFocus
                    name="email"
                    placeholder="Email"
                    className="input"
                    value={email}
                    onKeyPress={reValidate}
                    onBlur={onChange}
                    onChange={onChange} />
                </div>

                <div className="control">
                  <a onClick={doLogin} disabled={!valid} className="button is-info">
                    Sign In
                  </a>
                </div>
              </div>
            </form>
          </div>
          <div className="column is-on-third"></div>
        </div>
        <div className="has-text-centered"><small>© 2021 ridesnridesnrides.com</small></div>
      </div>
    </div>
  </div>
}
