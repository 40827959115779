// ----------------------
// LOCATIONS
// ----------------------

// ----------------------
// RIDES
// ----------------------
export const LOADING_RIDES = 'LOADING_RIDES';
export const FILTER_RIDES = 'FILTER_RIDES';
export const FILTER_SEARCH = 'FILTER_SEARCH';
export const TOGGLE_CANCELED = 'TOGGLE_CANCELED';
export const SET_COLORS = 'SET_COLORS';

// ----------------------
// USERS
// ----------------------
export const LOADING = 'LOADING';
export const LOGIN  = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';

// ----------------------
// ENTITIES
// ----------------------
export const CLEAR_ENTITIES = 'CLEAR_ENTITIES';
export const CLEAR_ENTITY = 'CLEAR_ENTITY';
export const RECEIVE_ENTITIES = 'RECEIVE_ENTITIES';
export const RECEIVE_ENTITY = 'RECEIVE_ENTITY';

// ----------------------
// REPORTS
// ----------------------
export const LOADING_REPORT = 'LOADING_REPORT';
