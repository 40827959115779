import { Record } from 'immutable';

export default class AddressRecord extends Record({
    id: null,
    address1: '',
    address2: '',
    label: '',
    city: '',
    state: '',
    postal_code: '',
    organization_id: null
  }) {
    get display() {
      if (this.postal_code !== '00000' && this.postal_code !== '00000-0000') {
        return this.address1 + ' ' + this.city + ' ' + this.state + ' ' + this.postal_code;
      } else {
        return this.address1 + ' ' + this.city + ' ' + this.state;
      }
    }
  }
