import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import AddressRecord from '../../records/address';
import PhoneRecord from '../../records/phone';

import { createLocation } from '../../actions/locations';
import { createUser } from '../../actions/users';
import { createPhone } from '../../actions/phones';

export default function NewUser({ user, dismiss, callback }) {
  const dispatch = useDispatch();
  const [active, setActive] = useState('personal');
  const [errors, setErrors] = useState([]);
  const [stateUser, setStateUser] = useState(user);
  const [address, setAddress] = useState(new AddressRecord({ state: 'VT', organization_id: user.organization_id }));
  const [primaryPhone, setPrimaryPhone] = useState(new PhoneRecord({ label: 'Primary', primary: true }));
  const [alternatePhone, setAlternatePhone] = useState(new PhoneRecord({ label: 'Alternate', primary: false }));

  const currentUser = useSelector(state => state.users.get('user'));
  const org = useSelector(state => state.entities
    .get('organization')
    .get(user.organization_id));

  useEffect(() => {
    if (stateUser && stateUser.role('passenger') && !stateUser.email) {
      setStateUser(stateUser.set('email', `${Date.now()}@ridesnridesnrides.com`));
    }
  });

  const toggleSection = section => {
    setActive(section);
  }

  const onChangeVehicle = e => setStateUser(stateUser.set('vehicle_preference', e.target.value));

  const onChangeNotification = e => setStateUser(stateUser.set('email_digest', !stateUser.email_digest));

  const onUserChange = e => setStateUser(stateUser.set(e.target.name, e.target.value));

  const onAddressChange = e => {
    setAddress(address.set(e.target.name, e.target.value));
  }

  const onRoleChange = e => {
    let roles = stateUser.roles;
    let index = roles.findIndex(r => r === e.target.name);
    if (index >= 0) roles.splice(index, 1);
    if (index < 0) roles.push(e.target.name);

    setStateUser(stateUser.set('roles', roles));
  }

  const onChangeAvailability = e => {
    let days = stateUser.toJS().availability;
    const index = days.indexOf(e.target.value);

    index >= 0 ? days.splice(index, 1) : days.push(e.target.value);

    setStateUser(stateUser.set('availability', days));
  }

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !address.postal_code.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection
    };
  }

  const validateEmail = () => {
    // allow for optional email
    if (stateUser.email === '') return true;

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (re.test(stateUser.email));
  }

  const save = () => {
    let errors = [];
    if (!validateEmail()) {
      errors.push('email')
      setActive('personal');
    }

    if (stateUser.first_name === '') {
      errors.push('first_name');
      setActive('personal');
    }

    if (stateUser.last_name === '') {
      errors.push('last_name');
      setActive('personal');
    }

    if (address.address1 !== '') {
      if (address.city === '') {
        errors.push('city');
        setActive('address');
      }

      if (address.state === '') {
        errors.push('state');
        setActive('address');
      }

      if (address.postal_code === '') {
        errors.push('postal_code');
        setActive('address')
      }
    }

    if (errors.length > 0) {
      setErrors(errors);
      return false;
    }

    const home = new Promise((resolve, reject) => {
      if (address.address1 !== '') {
        resolve(dispatch(createLocation(address)));
      } else {
        resolve({ payload: { data: { id: null } } });
      }
    });

    let homeId;

    return home
      .then(res => {
        homeId = res.payload.data.id;
        return dispatch(createUser(stateUser.set('home_id', homeId)))
      })
      .then(res => {
        primaryPhone.isValid && dispatch(createPhone(primaryPhone.set('user_id', res.payload.data.id)));
        alternatePhone.isValid && dispatch(createPhone(alternatePhone.set('user_id', res.payload.data.id)));
        return res;
      })
      .then(res => callback(stateUser.set('id', res.payload.data.id).set('home_id', homeId)))
      .then(() => dismiss())
      .catch(err => setErrors(['unique']));
  }

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Adding User ({stateUser.roles[0]})</p>
          <button className="delete" aria-label="close" onClick={() => dismiss.call()}></button>
        </header>
        <section className="modal-card-body">

          <div className="tabs">
            <ul>
              <li className={classNames({
                'is-active': active === 'personal'
              })} onClick={() => toggleSection('personal')}><a>Personal</a></li>
              <li className={classNames({
                'is-active': active === 'address'
              })} onClick={() => toggleSection('address')}><a>Address</a></li>
              <li className={classNames({
                'is-active': active === 'preferences'
              })} onClick={() => toggleSection('preferences')}><a>Preferences</a></li>
              {currentUser.role('admin') && <li className={classNames({
                'is-active': active === 'note'
              })} onClick={() => toggleSection('note')}><a>Note</a></li>}
            </ul>
          </div>

          {active === 'personal' && <div id="personal">
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">First Name</label>
                  <div className="control">
                    <input className="input" type="text" name="first_name" onChange={onUserChange} value={stateUser.first_name} />
                  </div>
                  {errors.includes('first_name') && <p className="help is-danger">First name is required</p>}
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label">Last Name</label>
                  <div className="control">
                    <input className="input" type="text" name="last_name" onChange={onUserChange} value={stateUser.last_name} />
                  </div>
                  {errors.includes('last_name') && <p className="help is-danger">Last name is required</p>}
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">Email</label>
                  <div className="control">
                    <input className="input" type="text" name="email" onChange={onUserChange} value={stateUser.email} />
                  </div>
                  {errors.includes('email') && <p className="help is-danger">This email is invalid</p>}
                  {errors.includes('unique') && <p className="help is-danger">This email is already in use</p>}
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label">Primary Phone</label>
                  <div className="control">
                    <InputMask
                      className="input"
                      type="text"
                      name="primary_phone"
                      mask="(999) 999-9999"
                      onChange={e => setPrimaryPhone(primaryPhone.set('number', e.target.value))}
                      value={primaryPhone.number}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                {stateUser.roles[0] === 'driver' && <div className="field">
                  <label className="checkbox">
                    <input type="checkbox" name="admin" onChange={onRoleChange} checked={stateUser.roles.includes('admin')} />
                    Also add as an admin
                  </label>
                </div>}
                {stateUser.roles[0] === 'admin' && <div className="field">
                  <label className="checkbox">
                    <input type="checkbox" name="driver" onChange={onRoleChange} checked={stateUser.roles.includes('driver')} />
                    &nbsp;Also add as a driver
                  </label>
                </div>}
              </div>
              <div className="column">
                <div className="field">
                  <label className="label">Alternate Phone</label>
                  <div className="control">
                    <InputMask
                      className="input"
                      type="text"
                      name="alternate_phone"
                      mask="(999) 999-9999"
                      onChange={e => setAlternatePhone(alternatePhone.set('number', e.target.value))}
                      value={alternatePhone.number}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>}

          {active === 'address' && <div id="address">
            <div className="subtitle">Address</div>

            <div className="field">
              <label className="label">Label</label>
              <div className="control">
                <input className="input" type="text" name="label" onChange={onAddressChange} value={address.label} placeholder="Address Type" />
              </div>
            </div>

            <div className="field">
              <label className="label">Street</label>
              <div className="control">
                <input className="input" type="text" name="address1" onChange={onAddressChange} value={address.address1} />
              </div>
            </div>

            <div className="field">
              <label className="label">Apt</label>
              <div className="control">
                <input className="input" type="text" name="address2" onChange={onAddressChange} value={address.address2} />
              </div>
            </div>

            <div className="columns">
              <div className="column is-half">
                <div className="field">
                  <label className="label">City</label>
                  <div className="control">
                    <input className="input" type="text" name="city" onChange={onAddressChange} value={address.city} />
                  </div>
                  {errors.includes('city') && <p className="help is-danger">City is required</p>}
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label">State</label>
                  <div className="control">
                    <input className="input" type="text" name="state" onChange={onAddressChange} value={address.state} />
                  </div>
                  {errors.includes('state') && <p className="help is-danger">State is required</p>}
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label">Zip</label>
                  <div className="control">
                    <InputMask
                      className="input"
                      type="text"
                      name="postal_code"
                      mask="99999-9999"
                      maskChar={null}
                      beforeMaskedValueChange={beforeMaskedValueChange}
                      onChange={onAddressChange}
                      value={address.postal_code}
                    />
                  </div>
                  {errors.includes('postal_code') && <p className="help is-danger">Zip code is required</p>}
                </div>
              </div>
            </div>
          </div>}

          {active === 'preferences' && <div id="preferences">
            <div className="subtitle">Preferences</div>

            {stateUser.roles[0] === 'driver' && <div className="field">
              <strong>Vehicle Preference:</strong>
              <ul>
                <li><label className="radio"><input type="radio" name="vehicle_preference" onChange={onChangeVehicle} checked={stateUser.vehicle_preference === 'provided'} value="provided" /> {org.name} vehicle only</label></li>
                <li><label className="radio"><input type="radio" name="vehicle_preference" onChange={onChangeVehicle} checked={stateUser.vehicle_preference === 'personal'} value="personal" /> Personal vehicle only</label></li>
                <li><label className="radio"><input type="radio" name="vehicle_preference" onChange={onChangeVehicle} checked={stateUser.vehicle_preference === 'both' || stateUser.vehicle_preference === ''} value="both" /> No vehicle preference</label></li>
              </ul>
            </div>}

            {stateUser.roles[0] === 'driver' && <div className="field">
              <p><strong>Availbility</strong></p>

              <label className="checkbox" style={{ marginRight: '5px' }}>
                <input
                  type="checkbox"
                  name="availability"
                  value="sunday"
                  onChange={onChangeAvailability}
                  checked={stateUser.available('sunday')}
                /> Sunday
              </label>

              <label className="checkbox" style={{ marginRight: '5px' }}>
                <input
                  type="checkbox"
                  name="availability"
                  value="monday"
                  onChange={onChangeAvailability}
                  checked={stateUser.available('monday')}
                /> Monday
              </label>

              <label className="checkbox" style={{ marginRight: '5px' }}>
                <input
                  type="checkbox"
                  name="availability"
                  value="tuesday"
                  onChange={onChangeAvailability}
                  checked={stateUser.available('tuesday')}
                /> Tuesday
              </label>

              <label className="checkbox" style={{ marginRight: '5px' }}>
                <input
                  type="checkbox"
                  name="availability"
                  value="wednesday"
                  onChange={onChangeAvailability}
                  checked={stateUser.available('wednesday')} /> Wednesday
              </label>

              <label className="checkbox" style={{ marginRight: '5px' }}>
                <input
                  type="checkbox"
                  name="availability"
                  value="thursday"
                  onChange={onChangeAvailability}
                  checked={stateUser.available('thursday')}
                /> Thursday
              </label>

              <label className="checkbox" style={{ marginRight: '5px' }}>
                <input
                  type="checkbox"
                  name="availability"
                  value="friday"
                  onChange={onChangeAvailability}
                  checked={stateUser.available('friday')}
                /> Friday
              </label>

              <label className="checkbox" style={{ marginRight: '5px' }}>
                <input
                  type="checkbox"
                  name="availability"
                  value="saturday"
                  onChange={onChangeAvailability}
                  checked={stateUser.available('saturday')}
                /> Saturday
              </label>
            </div>}

            {stateUser.roles[0] === 'driver' && <div className="field">
              <p><strong>Notifications</strong></p>
              <label className="checkbox" style={{ marginRight: '5px' }}>
                <input type="checkbox" name="email_digest" value={stateUser.email_digest} onChange={onChangeNotification} checked={stateUser.email_digest} /> Email Digest
              </label>
              <p><small>Digests are sent once a day when rides are added, modified or canceled</small></p>
            </div>}

            <div className="field">
              <label className="label">Sales Force ID</label>
              <div className="control">
                <input className="input" type="text" name="salesforce_id" onChange={onUserChange} value={stateUser.salesforce_id} />
              </div>
            </div>
          </div>}

          {active === 'note' && <div id="note">
            <div className="subtitle">Notes</div>
            <textarea
              style={{ width: '100%' }}
              name="note"
              rows="10"
              value={stateUser.note}
              onChange={onUserChange} />
          </div>}
        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={save}>Save changes</button>
          <button className="button" onClick={() => dismiss.call()}>Cancel</button>
        </footer>
      </div>
    </div>
  );
}
