import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { OrderedMap } from 'immutable';
import { sorted, time_convert } from '../../lib/utils';
import { getRides } from '../../actions/rides';
import EditRide from './EditRide';
import useRide from '../../hooks/useRide';
import Mobile from './schedule/Mobile';
import Desktop from './schedule/Desktop';

export default function Schedule() {
  const dispatch = useDispatch();
  const rideHelpers = useRide();
  const search = useSelector(state => state.rides.get('search'));
  const entities = useSelector(state => state.entities);
  const rides = useSelector(state => state.entities.get('ride'));
  const locations = useSelector(state => state.entities.get('location'));
  const phones = useSelector(state => state.entities.get('phone_number'));
  const users = useSelector(state => state.entities.get('user'));
  const user = useSelector(state => state.users.get('user'));

  const org = useSelector(state => state.entities
    .get('organization')
    .get(user.organization_id));

  const [toggleSort, setToggleSort] = useState('when');
  const [desc, setDesc] = useState(true);
  const [editing, setEditing] = useState({ ride: false });
  const [detail, setDetail] = useState(null);
  const [data, setData] = useState(new OrderedMap());

  useEffect(() => {
    dispatch(getRides());
  }, [search]);

  useEffect(() => {
    if (rides.count() >= 1) {
      let d = rides
        .sort((a, b) => sorted(a, b, toggleSort, entities))
        .valueSeq();

      setData(desc ? d : d.reverse());
    }
  }, [rides]);

  const setDetailView = ride => {
    setDetail(ride.id === detail ? ride.id : null);
  }

  const edit = ride => {
    setEditing({ ride: ride });
  }

  const sort = column => {
    setToggleSort(column);
    setDesc(!desc);
  }

  if (rides.get('loading') || data.count() <= 0) return <progress className="progress is-small is-primary" max="100">15%</progress>

  return <div>
    <Desktop
      edit={edit}
      setDetailView={setDetailView}
      sort={sort}
      rides={data}
    />

    {editing.ride &&
      <EditRide 
        dismiss={edit.bind(null, false)} 
        ride={editing.ride} 
    />}
  </div>
}
