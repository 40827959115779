import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { isUserLoggedIn, logout } from '../actions/users';

export default function Nav() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.users.get('user'));
  const org = useSelector(state => state.entities.get('organization').get(user.organization_id));
  const loading = useSelector(state => state.users.get('loading'));

  const [nav, setNav] = useState(false);

  useEffect(() => {
    if (!user.is_logged_in) {
      dispatch(isUserLoggedIn());
    }
  }, []);

  const toggleNav = () => setNav(!nav);

  if (!user.is_logged_in || loading) return <></>;

  return <nav className="navbar">
    <div className="navbar-brand">
      <Link to="/" className="navbar-item">
        <img src={org.logo_url} /> <span className="is-size-5 has-text-success" style={{ marginLeft: '5px' }}>{org.name}</span>
      </Link>
      <span className={classNames('navbar-burger burger', {
        'is-active': nav
      })} onClick={toggleNav} data-target="navbarMenuHeroA">
        <span></span>
        <span></span>
        <span></span>
      </span>
    </div>
    <div className={classNames('navbar-menu', {
      'is-active': nav
    })}>
      <div className="navbar-end">
        {window.location.pathname !== '/' &&
          <span className="navbar-item">
            <Link to="/" className="button is-primary is-inverted">
              <span className="icon">
                <i className="fa fa-home"></i>
              </span>
              <span>Home</span>
            </Link>
          </span>}
        {!user.is_logged_in && !loading &&
          <span className="navbar-item">
            <Link className="button is-info is-inverted" to="/login">
              <span className="icon">
                <i className="fa fa-sign-in-alt"></i>
              </span>
              <span>Login</span>
              </Link>
            </span>}
        {user.is_logged_in && user.role('admin') && <span className="navbar-item">
          <Link to="/admin" onClick={toggleNav} className="button is-primary is-inverted">
            <span className="icon">
              <i className="fa fa-cog"></i>
            </span>
            <span>Admin</span>
          </Link> 
        </span>}
        {user.is_logged_in && !user.role('rider') && <span className="navbar-item">
          <Link to="/driver/schedule" onClick={toggleNav} className="button is-primary is-inverted">
            <span className="icon">
              <i className="fa fa-car"></i>
            </span>
            <span>Drivers</span>
          </Link>
        </span>}
        {user.is_logged_in && user.role('rider') && <a className="navbar-item">
          Request a Ride
        </a>}
      </div>
    </div>
  </nav>
}
