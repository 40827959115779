import React from 'react';
import { connect } from 'react-redux'; 

import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

import { isUserLoggedIn } from './actions/users';

import Main from './components/Main';

import Nav from './components/Nav';
import Footer from './components/Footer';
import { ValidateAuthContainer } from './components/ValidateAuth';
import AdminIndex from './components/admin/AdminIndex';
import DriverIndex from './components/driver/DriverIndex';
import Register from './components/Register';

import './styles/App.scss';

class PrivateRouteContainer extends React.Component {
  state = {
    loading: true
  }

  componentDidMount() {
    if (!this.props.users.get('user').is_logged_in) {
      this.props.isUserLoggedIn()
        .then(res => this.setState({ loading: false }));
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      users,
      component: Component,
      ...props
    } = this.props

    if (this.state.loading) return <progress className="progress is-small is-primary" max="100">15%</progress>;

    return (
      <Route
        {...props}
        render={props =>
          users.get('user').is_logged_in ? <Component {...props} /> : (
            <Redirect to={{
              pathname: '/',
              state: { from: props.location }
            }} />
      )} />
    )
  }
}

const PrivateRoute = connect(state => ({
  users: state.users
}), {
  isUserLoggedIn: isUserLoggedIn
})(PrivateRouteContainer);

const ConnectedSwitch = connect(state => ({
  location: state.location
}))(Switch)

function App() {
  return (
    <div className="site">
      <Nav />

      <ConnectedSwitch>
        <Route exact path="/" component={Main} />
        <Route path="/register" component={Register} />
        <Route path="/auth/:token" component={ValidateAuthContainer} />
        <PrivateRoute path="/admin" component={AdminIndex} />
        <PrivateRoute path="/driver/:id" component={DriverIndex} />
        <PrivateRoute path="/driver" component={DriverIndex} />
      </ConnectedSwitch>

      <Footer />
    </div>
  );
}

export default App;
