export const sorted = (a, b, column, state) => {
  if (a.mapped(column, state) < b.mapped(column, state)) { return -1; }
  if (a.mapped(column, state) > b.mapped(column, state)) { return 1; }
  if (a.mapped(column, state) === b.mapped(column, state)) { return 0; }
}

export const time_convert = num => { 
  const hours = Math.floor(num / 60);  
  const minutes = num % 60;

  let extra = '';
  if (hours > 1) extra = 's';

  if (hours >= 1 && minutes > 1) {
    return `${hours} hr${extra} ${minutes} mins`;         
  } else if (hours >= 1) {
    return `${hours} hr${extra}`;
  } else {
    return `${minutes} mins`;
  }
}
