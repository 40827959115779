import { Record } from 'immutable';

export default class PhoneRecord extends Record({
    id: null,
    user_id: null,
    label: '',
    primary: true,
    number: ''
  }) {
    get isValid() {
      return this.number !== '';
    }
  }
