import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import DatePicker from "react-datepicker";
import Schedule from './Schedule';
import EditUser from '../admin/EditUser';
import { filter, search } from '../../actions/rides';
import { createLocation, updateLocation } from '../../actions/locations';
import { updatePhone } from '../../actions/phones';
import { updateUser, updateCurrentUser } from '../../actions/users';

import "react-datepicker/dist/react-datepicker.css";

export default function DriverIndex({ match }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.users.get('user'));
  const colors = useSelector(state => state.rides.get('colors'));

  const [searchState, setSearchState] = useState('');
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [view, setView] = useState('all');

  const doFilter = () => {
    dispatch(filter(start, end));
  }

  const doSearch = () => {
    dispatch(search(searchState));
  }

  return (
    <section className="section index">
      <div className="is-hidden-tablet">
        <div className="tabs is-centered">
          <ul>
            <li className={classNames({
              'is-active': view === 'all'
            })}><a onClick={() => setView('all')}><i className="fa fa-car"></i> All Rides</a></li>
            <li className={classNames({
              'is-active': view === 'available'
            })}><a onClick={() => setView('available')}><i className="fa fa-car"></i> Available Rides</a></li>
            <li className={classNames({
              'is-active': view === 'mine'
            })}><a onClick={() => setView('mine')}><i className="fa fa-user-tag"></i> My Rides</a></li>
            <li className={classNames({
              'is-active': view === 'account'
            })}><a onClick={() => setView('account')}><i className="fa fa-user-circle"></i> Account</a></li>
          </ul>
        </div>

        <div className="columns is-gapless is-mobile" style={{ marginBottom: '10px' }}>
          <div className="column">
            <DatePicker
              name="start"
              className="input"
              isClearable={true}
              selected={start}
              onChange={e => setStart(e.target.value)} />
          </div>
          <div className="column is-narrow">
            <span style={{ padding: '0 5px 0 5px' }}>to</span>
          </div>
          <div className="column">
            <DatePicker
              name="end"
              className="input"
              isClearable={true}
              selected={end}
              onChange={e => setEnd(e.target.value)} />
          </div>
          <div className="column">
            <button className="button is-fullwidth" style={{ marginLeft: '5px' }} onClick={doFilter}>Filter</button>
          </div>
        </div>
      </div>

      <div className="index-nav is-hidden-mobile" style={{ background: colors.get('2'), border: '1px solid ' + colors.get('3')  }}>
        <nav className="level">
          <div className="level-left">
            <div className="level-item">
              <DatePicker
                name="start"
                className="input"
                isClearable={true}
                selected={start}
                placeholderText="mm/dd/yyyy"
                onChange={e => setStart(e.target.value)} />
              <span style={{ padding: '0 5px 0 5px' }}>to</span>
              <DatePicker
                name="end"
                className="input"
                isClearable={true}
                selected={end}
                placeholderText="mm/dd/yyyy"
                onChange={e => setEnd(e.target.value)} />

              <span style={{ paddingLeft: '5px' }}><button className="button" onClick={doFilter}>Filter</button></span>
            </div>
          </div>

          <div className="level-right">
            <p className="level-item"><a style={{ fontWeight: view === 'all' ? 'bold' : 'normal'}} onClick={() => setView('all')}><i className="fa fa-car"></i> All Rides</a></p>
            <p className="level-item"><a style={{ fontWeight: view === 'available' ? 'bold' : 'normal'}} onClick={() => setView('available')}><i className="fa fa-car"></i> Available Rides</a></p>
            <p className="level-item"><a style={{ fontWeight: view === 'mine' ? 'bold' : 'normal'}} onClick={() => setView('mine')}><i className="fa fa-user-tag"></i> My Rides</a></p>
            <p className="level-item"><a style={{ fontWeight: view === 'account' ? 'bold' : 'normal'}} onClick={() => setView('account')}><i className="fa fa-user-circle"></i> Account</a></p>
          </div>
        </nav>
      </div>

      {view === 'all' && <Schedule all={true} />}
      {view === 'available' && <Schedule match={match} />}
      {view === 'mine' && <Schedule mine={true} />}
      {view === 'account' && <EditUser 
        user={user} 
        dismiss={setView.bind(null, 'available')} />}
    </section>
  );
}
