import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import { createLocation } from '../../actions/locations';

export default function NewAddress({ address, callback, dismiss }) {
  const dispatch = useDispatch();
  const [addressState, setAddressState] = useState(address);
  const [errors, setErrors] = useState([]);

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !addressState.postal_code.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection
    };
  }

  const save = () => {
    let errors = [];

    if (addressState.address1 === '') {
      errors.push('address1');
    }

    if (addressState.city === '') {
      errors.push('city');
    }

    if (addressState.state === '') {
      errors.push('state');
    }

    if (addressState.postal_code === '') {
      errors.push('postal_code');
    }

    if (errors.length > 0) {
      return setErrors(errors);
    }

    dispatch(createLocation(addressState))
      .then(res => callback(addressState.set('id', res.payload.data.id)))
      .then(res => dismiss());
  }

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Adding Address</p>
          <button className="delete" aria-label="close" onClick={() => dismiss.call()}></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label className="label">Label</label>
            <div className="control">
              <input className="input" type="text" name="label" onChange={e => setAddressState(addressState.set('label', e.target.value))} value={addressState.label} placeholder="Address Label" />
            </div>
          </div>

          <div className="field">
            <label className="label">Street</label>
            <div className="control">
              <input className="input" type="text" name="address1" onChange={e => setAddressState(addressState.set('address1', e.target.value))} value={addressState.address1} />
            </div>
            {errors.includes('address1') && <p className="help is-danger">Street address is required</p>}
          </div>

          <div className="field">
            <label className="label">Apt</label>
            <div className="control">
              <input className="input" type="text" name="address2" onChange={e => setAddressState(addressState.set('address2', e.target.value))} value={addressState.address2} />
            </div>
          </div>

          <div className="columns">
            <div className="column is-half">
              <div className="field">
                <label className="label">City</label>
                <div className="control">
                  <input className="input" type="text" name="city" onChange={e => setAddressState(addressState.set('city', e.target.value))} value={addressState.city} />
                </div>
                {errors.includes('city') && <p className="help is-danger">City is required</p>}
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label">State</label>
                <div className="control">
                  <input className="input" type="text" name="state" onChange={e => setAddressState(addressState.set('state', e.target.value))} value={addressState.state} />
                </div>
                {errors.includes('state') && <p className="help is-danger">State is required</p>}
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label">Zip</label>
                <div className="control">
                  <InputMask 
                    className="input" 
                    type="text" 
                    name="postal_code" 
                    mask="99999-9999" 
                    maskChar={null} 
                    beforeMaskedValueChange={beforeMaskedValueChange} 
                    onChange={e => setAddressState(addressState.set('postal_code', e.target.value))} 
                    value={addressState.postal_code} />
                </div>
                {errors.includes('postal_code') && <p className="help is-danger">Zip code is required</p>}
              </div>
            </div>
          </div>

        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={save}>Save changes</button>
          <button className="button" onClick={() => dismiss.call()}>Cancel</button>
        </footer>
      </div>
    </div>
  );
}
