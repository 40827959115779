import { fromJS } from 'immutable';
import * as ActionTypes from '../constants/ActionTypes';

const locationsState = fromJS({
  loading: true
});

function setState(state, newState) {
  return state.merge(newState);
}

export default function locations(state = locationsState, action) {
  switch(action.type) {

    default:
      return state;
  }
}
