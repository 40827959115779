import Network from '../lib/network';
import * as ActionTypes from '../constants/ActionTypes';
import moment from 'moment';
import { dateFormat } from '../constants/SiteVariables';

const api = Network();

const path = resource => {
  if (!resource) resource = '';
  return { resource: 'reports/' + resource };
}

export const loading = state => ({
  type: ActionTypes.LOADING_REPORT,
  payload: state
});

export const createReport = type => {
  let filters = ['start=' + moment(moment().format('YYYY-MM-DD') + ' 00:01:00').format(dateFormat)];

  return (dispatch, getState) => {
    dispatch(loading(true));

    const search = getState().rides.get('search');

    if (search.get('start')) {
      filters = ['start=' + moment(search.get('start')).format(dateFormat)]

      if (search.get('end')) {
        filters.push('end=' + moment(search.get('end')).format(dateFormat));
      }
    }

    if (getState().rides.get('canceled')) {
      filters.push('canceled=true');
    }

    filters.push('report=true');

    return api.get({ 
      resource: 'rides/' + (filters.length > 0 ? '?' + filters.join('&') : '') 
    })
    .then(res => dispatch(loading(false)));
  }
}
