import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import { getRide, getRides, updateRide } from '../../actions/rides';

export default function Claim({ ride, dismiss }) {
  const dispatch = useDispatch();
  const users = useSelector(state => state.entities.get('user'));
  const locations = useSelector(state => state.entities.get('location'));
  const user = useSelector(state => state.users.get('user'));
  const rides = useSelector(state => state.entities.get('ride'));
  const entities = useSelector(state => state.entities);
  const org = useSelector(state => state.entities
    .get('organization')
    .get(user.organization_id));

  const vehicle = user.vehicle_preference || ride.vehicle;

  const [claimed, setClaimed] = useState(ride.driver_id);

  const claimedByMe = () => claimed && ride.driver_id === user.id;

  const canBeUnclaimed = () => {
    if (moment() >= moment(ride.when).subtract(24, 'hours')) {
      return false;
    }

    return true;
  }

  const save = () => {
    dispatch(getRide(ride))
      .then(res => rides.get(ride.id))
      .then(ride => {
        return dispatch(updateRide(
          ride
            .set('appointment', moment(ride.appointment).format('HH:mm'))
            .set('vehicle', user.vehicle_preference || ride.vehicle),
          ride.canceled,
          claimedByMe() ? null : user,
          users.get(ride.passenger_id),
          locations.get(ride.pickup_id),
          locations.get(ride.dropoff_id)
        ))
        .then(res => dispatch(getRides()))
        .then(res => dismiss.call());

        return setClaimed(!!ride.driver.id);
      });
  }

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Claiming Ride on {moment(ride.when).format('MM/DD/YYYY')} at {moment(ride.when).format('hh:mm a')}</p>
          <button className="delete" aria-label="close" onClick={() => dismiss.call()}></button>
        </header>
        <section className="modal-card-body">
          {(!claimed || claimedByMe()) && <p>Are you sure you want <strong>{ride.driver_id ? 'Un-Claim' : 'Claim'}</strong> this ride?</p>}
          {(claimed && !claimedByMe()) && <div className="notification is-danger">Oops, looks like this ride has already been claimed</div>}
          {(!claimed || claimedByMe()) && <ul>
            <li><strong>Passenger:</strong> {ride.mapped('passenger_id', entities)}</li>
            <li><strong>Pickup:</strong> {ride.mapped('pickup_id', entities)}</li>
            <li><strong>Dropoff:</strong> {ride.mapped('dropoff_id', entities)}</li>
            <li><strong>Vehicle:</strong> {vehicle === 'provided' ? org.prefix : vehicle}</li>
            <li><strong>Notes:</strong> {ride.note}</li>
          </ul>}
          {!canBeUnclaimed() && <div className="has-text-centered notification is-danger" style={{ marginTop: '10px' }}>
            Unable to Un-Claim, ride is within 24 hours.<br/>Contact admin to make arrangements.
          </div>}
        </section>
        <footer className="modal-card-foot">
          {!claimed && <button className="button is-success" onClick={save}>Claim Ride</button>}
          {(claimedByMe() && canBeUnclaimed()) && <button className="button is-danger" onClick={save}>Un-Claim Ride</button>}
          <button className="button" onClick={() => dismiss.call()}>Cancel</button>
        </footer>
      </div>
    </div>
  );
}
