import { fromJS } from 'immutable';
import * as ActionTypes from '../constants/ActionTypes';

const reportsState = fromJS({
  loading: false
});

function setState(state, newState) {
  return state.merge(newState);
}

export default function reports(state = reportsState, action) {
  switch(action.type) {
    case ActionTypes.LOADING_REPORT:
      return setState(state, state.set('loading', action.payload));

    default:
      return state;
  }
}
