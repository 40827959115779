import * as ActionTypes from '../constants/ActionTypes';
import { push } from 'connected-react-router';
import moment from 'moment';
import Network from '../lib/network';
import { dateFormat } from '../constants/SiteVariables';

import {
  clearEntities,
  receiveEntity,
  receiveEntities
} from './entities';

const api = Network();

const path = resource => {
  if (!resource) resource = '';
  return { resource: 'rides/' + resource };
}

export const loading = bool => ({
  type:  ActionTypes.LOADING_RIDES,
  payload: bool
});

export const setColors = colors => ({
  type: ActionTypes.SET_COLORS,
  payload: colors
});

export const toggleCanceled = () => ({
  type: ActionTypes.TOGGLE_CANCELED
});

export const createRide = (ride, canceled, driver, passenger, pickup, dropoff) => {
  return (dispatch, getState) => {
    return api.post(path(), {
      when: ride.when,
      appointment: moment(moment(ride.when).format('YYYY-MM-DD') + ' ' + ride.appointment).toDate(),
      appointment_length: ride.appointment_length,
      vehicle: ride.vehicle,
      driver_id: driver.id ? driver.id : null,
      dropoff_id: dropoff.id,
      pickup_id: pickup.id,
      passenger_id: passenger.id,
      organization_id: getState().users.get('user').organization_id,
      note: ride.note,
      category: ride.category
    })
    .then(res => dispatch(receiveEntity(res)))
    .then(res => dispatch(push('/admin/schedule')));
  }
}

export const filter = (start, end) => ({
  type: ActionTypes.FILTER_RIDES,
  payload: {
    start: start,
    end: end && moment(end).add(1, 'days').toDate()
  }
});

export const search = term => ({
  type: ActionTypes.FILTER_SEARCH,
  payload: term
});

export const updateRide = (ride, canceled, driver, passenger, pickup, dropoff) => {
  return (dispatch, getState) => {
    const currentUser = getState().users.get('user');

    return api.edit(path(ride.id), {
      canceled: canceled,
      driver_id: driver ? driver.id : null,
      passenger_id: passenger.id,
      pickup_id: pickup.id,
      dropoff_id: dropoff.id,
      when: ride.when,
      appointment: moment(moment(ride.when).format('YYYY-MM-DD') + ' ' + ride.appointment).toDate(),
      appointment_length: ride.appointment_length,
      vehicle: ride.vehicle,
      note: ride.note,
      category: ride.category
    })
    .then(res => dispatch(receiveEntity(res)));
  }
}

export const getRide = ride => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    return api.get(path(ride.id))
      .then(res => dispatch(receiveEntity(res)))
      .then(res => dispatch(loading(false)));
  }
}

export const getRides = (filters = ['start=' + moment(moment().format('YYYY-MM-DD') + ' 00:01:00').format(dateFormat)]) => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    const search = getState().rides.get('search');

    if (search.get('start')) {
      filters = ['start=' + moment(search.get('start')).format(dateFormat)]

      if (search.get('end') && moment(search.get('end')).isValid()) {
        filters.push('end=' + moment(search.get('end')).format(dateFormat));
      }
    }

    if (getState().rides.get('canceled')) {
      filters.push('canceled=true');
    }

    dispatch(clearEntities('ride'));

    return api.get(path(filters.length > 0 ? '?' + filters.join('&') : ''))
      .then(res => dispatch(receiveEntities(res)))
      .then(res => dispatch(loading(false)));
  }
}
