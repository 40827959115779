import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route } from 'react-router-dom'
import classNames from 'classnames';
import DatePicker from "react-datepicker";
import EditRide from './EditRide';
import Schedule from './Schedule';
import Users from './Users';
import { getRides, filter, search, toggleCanceled } from '../../actions/rides';
import { createReport } from '../../actions/reports';

import "react-datepicker/dist/react-datepicker.css";

export default function AdminIndex() {
  const dispatch = useDispatch();
  const rides = useSelector(state => state.rides);
  const reports = useSelector(state => state.reports);
  const colors = useSelector(state => state.rides.get('colors'));

  const [adding, setAdding] = useState({ resource: null });
  const [searchState, setSearchState] = useState('');
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const doFilter = () => dispatch(filter(start, end));
  const doSearch = () => dispatch(search(searchState));

  const toggleCancels = () => {
    dispatch(toggleCanceled());
    dispatch(getRides());
  }

  const doReport = () => {
    const type = window.location.pathname.split('/').pop();
    dispatch(createReport(type));
  }

  return (
    <section className="section index">
      <div className="is-hidden-tablet">
        <div className="tabs is-centered">
          <ul>
            <li className={classNames({
              'is-active': window.location.pathname === '/admin'
            })}><Link to="/admin"><i className="fa fa-calendar-alt"></i></Link></li>
            <li className={classNames({
              'is-active': window.location.pathname === '/admin/users/passenger'
            })}><Link to="/admin/users/passenger"><i className="fa fa-user"></i></Link></li>
            <li className={classNames({
              'is-active': window.location.pathname === '/admin/users/driver'
            })}><Link to="/admin/users/driver"><i className="fa fa-car"></i></Link></li>
            <li className={classNames({
              'is-active': window.location.pathname === '/admin/users/admin'
            })}><Link to="/admin/users/admin"><i className="fa fa-cog"></i></Link></li>
            <li className={classNames({
              'is-active': rides.get('canceled')
            })}><a onClick={toggleCancels}><i className="fa fa-ban"></i></a></li>
            <li className={classNames({
              'is-active': window.location.pathname === '/admin/add_ride'
            })}><Link to="/admin/add_ride"><i className="fa fa-plus"></i></Link></li>
          </ul>
        </div>

        <div className="columns is-gapless is-mobile" style={{ marginBottom: '10px' }}>
          <div className="column">
            <DatePicker
              name="start"
              className="input"
              isClearable={true}
              selected={start}
              onChange={e => setStart(e)} />
          </div>
          <div className="column is-narrow">
            <span style={{ padding: '0 5px 0 5px' }}>to</span>
          </div>
          <div className="column">
            <DatePicker
              name="end"
              className="input"
              isClearable={true}
              selected={end}
              onChange={e => setEnd(e)} />
          </div>
          <div className="column">
            <button className="button is-fullwidth" style={{ marginLeft: '5px' }} onClick={doFilter}>Filter</button>
          </div>
        </div>
      </div>

      <div className="index-nav is-hidden-mobile" style={{ background: colors.get('2'), border: '1px solid ' + colors.get('3')  }}>
        <nav className="level">
          <div className="level-left">
            <div className="level-item">
              <DatePicker
                name="start"
                className="input"
                isClearable={true}
                selected={start}
                placeholderText="mm/dd/yyyy"
                onChange={e => setStart(e)} />
              <span style={{ padding: '0 5px 0 5px' }}>to</span>
              <DatePicker
                name="end"
                className="input"
                isClearable={true}
                selected={end}
                placeholderText="mm/dd/yyyy"
                onChange={e => setEnd(e)} />

              <span style={{ paddingLeft: '5px' }}><button className="button" onClick={doFilter}>Filter</button></span>
            </div>
            <div className="level-item">
              <a onClick={toggleCancels}>
                {!rides.get('canceled') && <span><i className="fa fa-toggle-off"></i></span>}
                {rides.get('canceled') && <span><i className="fa fa-toggle-on"></i></span>}
                <span style={{ margin: '0 0 0 5px' }}>Canceled</span>
              </a>
            </div>
          </div>

          <div className="level-right">
            <p className="level-item"><Link className={classNames({
              'has-text-weight-bold': window.location.pathname === '/admin'
            })} to="/admin"><i className="fa fa-calendar"></i> Schedule</Link></p>
            <p className="level-item"><Link className={classNames({
              'has-text-weight-bold': window.location.pathname === '/admin/users/passenger'
            })} to="/admin/users/passenger"><i className="fa fa-user"></i> Passengers</Link></p>
            <p className="level-item"><Link className={classNames({
              'has-text-weight-bold': window.location.pathname === '/admin/users/driver'
            })} to="/admin/users/driver"><i className="fa fa-car"></i> Drivers</Link></p>
            <p className="level-item"><Link className={classNames({
              'has-text-weight-bold': window.location.pathname === '/admin/users/admin'
            })} to="/admin/users/admin"><i className="fa fa-cog"></i> Admins</Link></p>
            {!reports.get('loading') && <p className="level-item">
              <a onClick={doReport} title="Download Report">
                <i className="fa fa-file-download"></i>
              </a>
            </p>}
            {reports.get('loading') && <p className="level-item"><i className="fa fa-circle-notch fa-spin"></i></p>}
            <p className="level-item"><Link className="button is-success" to="/admin/add_ride">New Ride</Link></p>
          </div>
        </nav>
      </div>

      <Route exact path="/admin" component={Schedule} />
      <Route exact path="/admin/schedule" component={Schedule} />
      <Route path="/admin/add_ride" component={EditRide} />
      <Route path="/admin/users/:type" component={Users} />
    </section>
  );
}
