import { Record } from 'immutable';

export default class OrganizationRecord extends Record({
    id: null,
    name: '',
    prefix: '',
    url: '',
    logo_url: '',
    description: ''
  }) {
    
  }
