import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { fromJS, isImmutable } from 'immutable';
import InputMask from 'react-input-mask';
import AddressRecord from '../../records/address';
import PhoneRecord from '../../records/phone';
import { deletePhone, updatePhone } from '../../actions/phones';
import { createLocation, updateLocation } from '../../actions/locations';
import { updateUser, updateCurrentUser, deleteUser } from '../../actions/users';

const priPhone = new PhoneRecord({ label: 'Primary', primary: true });
const altPhone = new PhoneRecord({ label: 'Alternate', primary: false });

export default function EditUser({ user, dismiss }) {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.users.get('user'));
  const phones = useSelector(state => state.entities.get('phone_number'));
  const locations = useSelector(state => state.entities.get('location'));
  const org = useSelector(state => state.entities.get('organization').get(user.organization_id));

  const [section, setSection] = useState('personal');
  const [errors, setErrors] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [userState, setUserState] = useState(user)
  const [address, setAddress] = useState(new AddressRecord({ organization_id: user.organization_id }))
  const [primaryPhone, setPrimaryPhone] = useState(priPhone);
  const [alternatePhone, setAlternatePhone] = useState(altPhone);

  useEffect(() => {
    setAddress(userState.home_id ? locations.get(userState.home_id) : new AddressRecord({ organization_id: user.organization_id }));
    setPrimaryPhone(userState.phone(phones));
    setAlternatePhone(userState.phone(phones, false));
  }, []);

  const onChangeVehicle = e => {
    setUserState(userState.set('vehicle_preference', e.target.value));
  }

  const onChangeAvailability = e => {
    let days = userState.availability;
    const index = days.indexOf(e.target.value);

    if (!isImmutable(days)) {
      days = fromJS(days);
    }

    index >= 0 ? days = days.splice(index, 1) : days = days.push(e.target.value);

    setUserState(userState.set('availability', days));
  }

  const onChangeNotification = e => {
    setUserState(userState.set(e.target.name, !userState[e.target.name]));
  }

  const onRoleChange = e => {
    let roles = userState.roles;
    let index = roles.findIndex(r => r === e.target.name);
    if (index >= 0 && roles.count() > 1) roles = roles.splice(index, 1);
    if (index < 0) roles = roles.push(e.target.name);

    setUserState(userState.set('roles', roles));
  }

  const clearPhone = phone => {
    dispatch(deletePhone(phone));

    phone.primary ? setPrimaryPhone(priPhone) : setAlternatePhone(altPhone);
  }

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (value && value.endsWith('-') && userInput !== '-' && (address.postal_code && !address.postal_code.endsWith('-'))) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection
    };
  }

  const validateEmail = () => {
    if (userState.email === '' || !userState.email) return true;

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (re.test(userState.email));
  }

  const save = () => {
    let errors = [];
    if (!validateEmail()) {
      errors.push('email')
    }

    if (userState.first_name === '') {
      errors.push('first_name');
    }

    if (userState.last_name === '') {
      errors.push('last_name');
    }

    if (address.address1 !== '') {
      if (address.city === '') {
        errors.push('city');
      }

      if (address.state === '') {
        errors.push('state');
      }

      if (address.postal_code === '') {
        errors.push('postal_code');
      }
    }

    if (errors.length > 0) {
      return setErrors(errors);
    }

    const home = new Promise((resolve, reject) => {
      if (address.address1 !== '' && !address.id) {
        resolve(dispatch(createLocation(address)));
      } else if (address.address1 !== '') {
        resolve(dispatch(updateLocation(address)))
      } else {
        resolve({ payload: { data: { id: null } } });
      }
    });

    return home
      .then(res => dispatch(updateUser(userState.set('home_id', res.payload.data.id))))
      .then(res => {
        primaryPhone.isValid && dispatch(updatePhone(primaryPhone.set('user_id', res.payload.data.id)));
        alternatePhone.isValid && dispatch(updatePhone(alternatePhone.set('user_id', res.payload.data.id)));
        return res;
      })
      .then(res => (userState.id === currentUser.id) && dispatch(updateCurrentUser(userState.set('is_logged_in', true))))
      .then(res => dismiss())
      .catch(err => dismiss());
  }

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Editing User ({userState.first_name} {userState.last_name})</p>
          <button className="delete" aria-label="close" onClick={() => dismiss.call()}></button>
        </header>
        <section className="modal-card-body">

          <div className="tabs">
            <ul>
              <li className={classNames({
                'is-active': section === 'personal'
              })} onClick={() => setSection('personal')}><a>Personal</a></li>
              <li className={classNames({
                'is-active': section === 'address'
              })} onClick={() => setSection('address')}><a>Address</a></li>
              <li className={classNames({
                'is-active': section === 'preferences'
              })} onClick={() => setSection('preferences')}><a>Preferences</a></li>
              {currentUser.role('admin') && <li className={classNames({
                'is-active': section === 'note'
              })} onClick={() => setSection('note')}><a>Note</a></li>}
            </ul>
          </div>

          {deleting && <div className="notification is-danger">
            Are you sure you want to delete this user?  <a onClick={deleteUser}>Yes</a> | <a onClick={deleting}>No</a>
          </div>}

          {section === 'personal' && <div id="personal">
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">First Name</label>
                  <div className="control">
                    <input className="input" type="text" name="first_name" onChange={e => setUserState(userState.set('first_name', e.target.value))} value={userState.first_name} />
                  </div>
                  {errors.includes('first_name') && <p className="help is-danger">First name is required</p>}
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label">Last Name</label>
                  <div className="control">
                    <input className="input" type="text" name="last_name" onChange={e => setUserState(userState.set('last_name', e.target.value))} value={userState.last_name} />
                  </div>
                  {errors.includes('last_name') && <p className="help is-danger">Last name is required</p>}
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">Email</label>
                  <div className="control">
                    <input className="input" type="text" name="email" onChange={e => setUserState(userState.set('email', e.target.value))} value={userState.email || ''} />
                  </div>
                  {errors.includes('email') && <p className="help is-danger">This email is invalid</p>}
                </div>
              </div>
              <div className="column">
                <div className="field has-addons">
                  <label className="label">Primary Phone</label>
                  <div className="control">
                    <InputMask
                      className="input"
                      type="text"
                      name="primary_phone"
                      mask="(999) 999-9999"
                      onChange={e => setPrimaryPhone(primaryPhone.set('number', e.target.value))}
                      value={primaryPhone.number}
                    />
                  </div>
                  {primaryPhone.id && <div className="control">
                    <a className="button is-warning" onClick={() => clearPhone(primaryPhone)}>
                      <i className="fa fa-times"></i>
                    </a>
                  </div>}
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="checkbox">
                    <input type="checkbox" name="admin" onChange={onRoleChange} checked={userState.roles.includes('admin')} /> Admin
                  </label>
                </div>
                <div className="field">
                  <label className="checkbox">
                    <input type="checkbox" name="driver" onChange={onRoleChange} checked={userState.roles.includes('driver')} /> Driver
                  </label>
                </div>
              </div>
              <div className="column">
                <div className="field has-addons">
                  <label className="label">Alternate Phone</label>
                  <div className="control">
                    <InputMask
                      className="input"
                      type="text"
                      name="alternate_phone"
                      mask="(999) 999-9999"
                      onChange={e => setAlternatePhone(alternatePhone.set('number', e.target.value))}
                      value={alternatePhone.number}
                    />
                  </div>
                  {alternatePhone.id && <div className="control">
                    <a className="button is-warning" onClick={() => clearPhone(alternatePhone)}>
                      <i className="fa fa-times"></i>
                    </a>
                  </div>}
                </div>
              </div>
            </div>
          </div>}

          {section === 'address' && <div id="address">
            <div className="subtitle">Address</div>

            <div className="field">
              <label className="label">Label</label>
              <div className="control">
                <input className="input" type="text" name="label" onChange={e => setAddress(address.set('label', e.target.value))} value={address.label} placeholder="Address Type" />
              </div>
            </div>

            <div className="field">
              <label className="label">Street</label>
              <div className="control">
                <input className="input" type="text" name="address1" onChange={e => setAddress(address.set('address1', e.target.value))} value={address.address1} />
              </div>
            </div>

            <div className="field">
              <label className="label">Apt</label>
              <div className="control">
                <input className="input" type="text" name="address2" onChange={e => setAddress(address.set('address2', e.target.value))} value={address.address2} />
              </div>
            </div>

            <div className="columns">
              <div className="column is-half">
                <div className="field">
                  <label className="label">City</label>
                  <div className="control">
                    <input className="input" type="text" name="city" onChange={e => setAddress(address.set('city', e.target.value))} value={address.city} />
                  </div>
                  {errors.includes('city') && <p className="help is-danger">City is required</p>}
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label">State</label>
                  <div className="control">
                    <input className="input" type="text" name="state" onChange={e => setAddress(address.set('state', e.target.value))} value={address.state} />
                  </div>
                  {errors.includes('state') && <p className="help is-danger">State is required</p>}
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label">Zip</label>
                  <div className="control">
                    <InputMask
                      className="input"
                      type="text"
                      name="postal_code"
                      mask="99999-9999"
                      maskChar={null}
                      beforeMaskedValueChange={beforeMaskedValueChange}
                      onChange={e => setAddress(address.set('postal_code', e.target.value))}
                      value={address.postal_code}
                    />
                  </div>
                  {errors.includes('postal_code') && <p className="help is-danger">Zip code is required</p>}
                </div>
              </div>
            </div>
          </div>}

          {section === 'preferences' && <div id="preferences">
            <div className="subtitle">Preferences</div>

            <div className="field">
              <label className="checkbox" style={{ marginRight: '5px' }}>
                <input type="checkbox" name="active" value={userState.active} onChange={e => setUserState(userState.set('active', !userState.active))} checked={userState.active} /> Active
              </label>
            </div>

            {!userState.role('passenger') && <div>
              <div className="field">
                <strong>Vehicle Preference:</strong>
                <ul>
                  <li><label className="radio"><input type="radio" name="vehicle_preference" onChange={onChangeVehicle} checked={userState.vehicle_preference === 'provided'} value="provided" /> {org.name} vehicle only</label></li>
                  <li><label className="radio"><input type="radio" name="vehicle_preference" onChange={onChangeVehicle} checked={userState.vehicle_preference === 'personal'} value="personal" /> Personal vehicle only</label></li>
                  <li><label className="radio"><input type="radio" name="vehicle_preference" onChange={onChangeVehicle} checked={userState.vehicle_preference === 'both' || userState.vehicle_preference === ''} value="both" /> No vehicle preference</label></li>
                </ul>
              </div>

              <div className="field">
                <p><strong>Availbility</strong></p>

                <label className="checkbox" style={{ marginRight: '5px' }}>
                  <input type="checkbox" name="availability" value="sunday" onChange={onChangeAvailability} checked={userState.available('sunday')} /> Sunday
                </label>

                <label className="checkbox" style={{ marginRight: '5px' }}>
                  <input type="checkbox" name="availability" value="monday" onChange={onChangeAvailability} checked={userState.available('monday')} /> Monday
                </label>

                <label className="checkbox" style={{ marginRight: '5px' }}>
                  <input type="checkbox" name="availability" value="tuesday" onChange={onChangeAvailability} checked={userState.available('tuesday')} /> Tuesday
                </label>

                <label className="checkbox" style={{ marginRight: '5px' }}>
                  <input type="checkbox" name="availability" value="wednesday" onChange={onChangeAvailability} checked={userState.available('wednesday')} /> Wednesday
                </label>

                <label className="checkbox" style={{ marginRight: '5px' }}>
                  <input type="checkbox" name="availability" value="thursday" onChange={onChangeAvailability} checked={userState.available('thursday')} /> Thursday
                </label>

                <label className="checkbox" style={{ marginRight: '5px' }}>
                  <input type="checkbox" name="availability" value="friday" onChange={onChangeAvailability} checked={userState.available('friday')} /> Friday
                </label>

                <label className="checkbox" style={{ marginRight: '5px' }}>
                  <input type="checkbox" name="availability" value="saturday" onChange={onChangeAvailability} checked={userState.available('saturday')} /> Saturday
                </label>
              </div>

              <div className="field">
                <p><strong>Notifications</strong></p>
                <label className="checkbox" style={{ marginRight: '5px' }}>
                  <input type="checkbox" name="email_digest" value={userState.email_digest} onChange={onChangeNotification} checked={userState.email_digest} /> Email Digest
                </label>
                <p><small>Digests are sent once a day when rides are added, modified or canceled</small></p>

                <label className="checkbox" style={{ marginRight: '5px' }}>
                  <input type="checkbox" name="email_reminder" value={userState.email_reminder} onChange={onChangeNotification} checked={userState.email_reminder} /> Email Reminder
                </label>
                <p><small>Ride reminders are sent the day before a claimed ride is scheduled</small></p>
              </div>

              <div className="field">
                <label className="label">Sales Force ID</label>
                <div className="control">
                  <input className="input" type="text" name="salesforce_id" onChange={e => setUserState(userState.set('salesforce_id', e.target.value))} value={userState.salesforce_id} />
                </div>
              </div>
            </div>}
          </div>}

          {section === 'note' && <div id="note">
            <div className="subtitle">Notes</div>
            <textarea
              style={{ width: '100%' }}
              rows="10"
              name="note"
              value={userState.note}
              onChange={e => setUserState(userState.set('note', e.target.value))} />
          </div>}

        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={save}>Save changes</button>
          <button className="button" onClick={() => dismiss.call()}>Cancel</button>
        </footer>
      </div>
    </div>
  );
}
