import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ping, logout } from '../actions/users';
import RequestAuth from './RequestAuth';

export default function Main() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.users.get('user'));
  const loading = useSelector(state => state.users.get('loading'));

  useEffect(() => {
    dispatch(ping());
  }, []);

  const logout = () => dispatch(logout());

  if (!loading && user.role('admin')) return <Redirect to="/admin" />
  if (!loading && user.role('driver')) return <Redirect to="/driver" />

  return <RequestAuth />
}
