import React from 'react';
import { useDispatch } from 'react-redux';
import { setColors } from '../../../actions/rides';

export default function Color({ color }) {
  const dispatch = useDispatch();

  return (
    <>
      <span style={{ cursor: 'pointer', border: '1px solid ' + color[3] }} onClick={() => dispatch(setColors(color))}>
        <span style={{ background: color[1] }}>&nbsp;</span>
        <span style={{ background: color[2] }}>&nbsp;</span>
        <span style={{ background: color[3] }}>&nbsp;</span>
        <span style={{ background: color[4] }}>&nbsp;</span>
        <span style={{ background: color[5] }}>&nbsp;</span>
      </span>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
    </>
  );
}
