import Network from '../lib/network';

import { 
  receiveEntity
} from './entities';

const api = Network();

const path = resource => {
  if (!resource) resource = '';
  return { resource: 'organizations/' + resource };
}

export const getOrganization = id => {
  return (dispatch, getState) => {
    return api.get(path(id))
      .then(res => dispatch(receiveEntity(res)));
  }
}
