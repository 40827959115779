import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DateTimePicker from 'react-datetime-picker';
import TimePicker from 'react-time-picker'
import TypeAhead from '../TypeAhead';
import { createUser } from '../../actions/users';
import { createLocation } from '../../actions/locations';
import { createRide, updateRide } from '../../actions/rides';
import UserRecord from '../../records/user';
import AddressRecord from '../../records/address';
import RideRecord from '../../records/ride';
import NewUser from './NewUser';
import NewAddress from './NewAddress';

const PASS = 'passenger';
const DRIV = 'driver';
const VEHICLE = {
  PL: 'personal',
  PD: 'provided'
};

function matchSuggestions(needle, haystack) {
  const inputValue = needle.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : haystack.filter(h =>
    (h.fullName).toLowerCase().slice(0, inputLength) === inputValue
  )
  .valueSeq()
  .toArray();
}

function matchLocationSuggestions(needle, haystack) {
  return haystack.filter(h => {
    let input = needle.trim().toLowerCase();
    let location = (h.display.trim()).toLowerCase();

    return !!location.match(new RegExp(input, 'i'));
  })
  .valueSeq()
  .toArray();
}

function suggestion(value, stripped = false) {
  if (!stripped) {
    return <span>
      {value.fullName} ({value.email})
    </span>;
  } else {
    return value.fullName + '( ' + value.email + ')';
  }
}

function locationSuggestion(value, stripped = false) {
  if (!stripped) {
    return <span>
      {value.display}
    </span>;
  } else {
    return value.display
  }
}

function setupFrequencyDays(ride) {
  let days = [];

  for(var i = 0; i <= 6; i++) {
    days.push({
      label: moment().day(i).format('dddd'),
      day: i,
      checked: parseInt(moment(ride.when).format('d')) === i
    });
  }

  return days;
}

export default function EditRide({ ride, dismiss }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const users = useSelector(state => state.entities.get('user'));
  const locations = useSelector(state => state.entities.get('location'));
  const user = useSelector(state => state.users.get('user'));

  const org = useSelector(state => state.entities
    .get('organization')
    .get(user.organization_id));

  const [rideState, setRideState] = useState(
    ride ?
      ride
        .set('when', moment(ride.when).toDate())
        .set('appointment', moment(ride.appointment).format('HH:mm'))
    :
      new RideRecord({
        vehicle: 'both',
        appointment: moment().format('HH:mm'),
        appointment_length: 0
      })
    );

  const [recurring, setRecurring] = useState(false);
  const [frequencyDay, setFrequencyDay] = useState(setupFrequencyDays(rideState));
  const [frequencyInterval, setFrequencyInterval] = useState('weekly');
  const [frequencyDuration, setFrequencyDuration] = useState(1);

  const [passenger, setPassenger] = useState(new UserRecord({ roles: [PASS], organization_id: user.organization_id }));
  const [driver, setDriver] = useState(new UserRecord({ roles: [DRIV], organization_id: user.organization_id }));
  const [pickup, setPickup] = useState(new AddressRecord({ organization_id: user.organization_id }));
  const [dropoff, setDropoff] = useState(new AddressRecord({ organization_id: user.organization_id }));
  const [errors, setErrors] = useState([]);

  const [newPassenger, setNewPassenger] = useState(false);
  const [newDriver, setNewDriver] = useState(false);
  const [newPickup, setNewPickup] = useState(false);
  const [newDropoff, setNewDropoff] = useState(false);
  const [differentPickup, setDifferentPickup] = useState(false);

  useEffect(() => {
    rideState.passenger_id && setPassenger(users.get(rideState.passenger_id));
    rideState.driver_id && setDriver(rideState.driver_id && users.get(rideState.driver_id));
    rideState.pickup_id && setPickup(locations.get(rideState.pickup_id));
    rideState.dropoff_id && setDropoff(locations.get(rideState.dropoff_id));
  }, []);

  useEffect(() => {
    if (!passenger || !ride) return false;

    if (ride.pickup_id) setPickup(locations.get(ride.pickup_id));

    if ((ride.pickup_id && passenger.home_id) && (ride.pickup_id !== passenger.home_id)) {
      setDifferentPickup(true);
    }

    if (passenger.home_id && !newPickup && !ride.pickup_id) {
      setPickup(locations.get(passenger.home_id));
    }
  }, [passenger]);

  const manageDays = day => {
    return setFrequencyDay(frequencyDay.map(d => {
      if (d.day === parseInt(day)) {
        d.checked ? d.checked = false : d.checked = true;
      }
      return d;
    }));
  }

  // returns an array of dates for the frequency interval, duration and days
  const calculateFrequencyDates = () => {
    const dates = [];

    if (recurring) {
      // get current week of year
      const week = moment(rideState.when).format('w');
      const time = moment(rideState.when).format('h:mm');

      if (frequencyInterval === 'weekly') {
        for(var i = 0; i <= (frequencyDuration * 4); i++) {
          let w = moment(rideState.when).weeks(parseInt(week) + i);

          for(var d of frequencyDay) {
            if (d.checked) {
              dates.push(moment(w.days(d.day).format('YYYY-MM-DD' + time), 'YYYY-MM-DD h:mm').toDate());
            }
          }
        }
      }
    }

    return dates;
  }

  const closeModal = () => dismiss ? dismiss.call() : history.push('/admin');

  const activate = () => update(false, true).then(() => closeModal());

  const cancel = () => update(true, false).then(() => closeModal());

  const save = () => {
    let errors = [];
    if (!passenger) {
      errors.push('passenger');
    }

    if (!pickup) {
      errors.push('pickup');
    }

    if (!dropoff) {
      errors.push('dropoff');
    }

    if (errors.length > 0) {
      return setErrors(errors);
    }

    update()
      .then(res => closeModal());
  }

  const update = (canceled = false, activated = false) => {
    let isCanceled = rideState.canceled;
    if (canceled) isCanceled = true
    if (activated) isCanceled = false

    const action = rideState.id ? updateRide : createRide;
    const dates = calculateFrequencyDates();
    const rides = dates.map(d => rideState.set('when', d));

    if (rides.length < 1) rides.push(rideState);

    return Promise.all(rides.map(r => dispatch(
      action(
        r,
        isCanceled,
        driver,
        passenger,
        !differentPickup ? locations.get(passenger.home_id) : pickup,
        dropoff
      )
    )));
  }

  const isToday = day => {
    return day === moment(rideState.when).format('d').parseInt();
  }

  return <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{rideState.id ? 'Edit Ride' : 'Add Ride'}</p>
        <button className="delete" aria-label="close" onClick={() => closeModal()}></button>
      </header>
      <section className="modal-card-body">

        <div className="field">
          <label className="label">Vehicle Type</label>
          <label className="radio">
            <input type="radio" name="vehicle" value={VEHICLE.PD} onChange={() => setRideState(rideState.set('vehicle', VEHICLE.PD))} checked={rideState.vehicle === VEHICLE.PD} /> {org.prefix} Vehicle
          </label>
          <label className="radio">
            <input type="radio" name="vehicle" value={VEHICLE.PL} onChange={() => setRideState(rideState.set('vehicle', VEHICLE.PL))} checked={rideState.vehicle === VEHICLE.PL} /> Personal Vehicle
          </label>
        </div>

        <div className="columns">
          <div className="column">
            <label className="label">Date/Time</label>
            <div className="control">
              <DateTimePicker
                value={rideState.when}
                onChange={date => setRideState(rideState.set('when', date))}
              />
            </div>
          </div>

          <div className="column">
            <label className="label">Category</label>
            <div className="select">
              <select value={rideState.category} name="category" onChange={e => setRideState(rideState.set('category', e.target.value))}>
                <option value="">-- Category --</option>
                <option value="food security">Food Security</option>
                <option value="socialization">Socialization</option>
                <option value="recreation/wellness">Recreation/Wellness</option>
                <option value="employment">Employment</option>
                <option value="medical">Medical</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-narrow">
            <label className="label">Appointment Time</label>
            <div className="control">
              <TimePicker
                value={rideState.appointment === 'Invalid date' ? moment(rideState.when).format('H:mm') : rideState.appointment}
                onChange={time => setRideState(rideState.set('appointment', time))}
              />
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label">Appointment Length (minutes)</label>
              <div className="control">
                <input
                  type="text"
                  name="appointmentLength"
                  className="input"
                  value={rideState.appointment_length}
                  onChange={e => setRideState(rideState.set('appointment_length', e.target.value))} />
              </div>
            </div>
          </div>
        </div>

        {!rideState.id && <div className="columns">
          <div className="column is-narrow">
          <label className="label">Ride Frequency</label>
            <input
              type="radio"
              name="frequency"
              checked={!recurring}
              readOnly={true}
              onClick={() => setRecurring(false)}
            /> Single<br/>
            <input
              type="radio"
              name="frequency"
              checked={recurring}
              readOnly={true}
              onClick={() => setRecurring(true)}
            /> Recurring
          </div>
          {recurring && <div className="column">
            <strong>Days:</strong><br/>
            {frequencyDay.map(d => <span key={d.day}>
              <input
                type="checkbox"
                name="frequency_day"
                value={d.day}
                checked={d.checked}
                onChange={e => manageDays(e.target.value)} /> {d.label}<br/>
            </span>)}
          </div>}
          {recurring && <div className="column">
            <strong>Repeat:</strong><br/>
            <input
              type="radio"
              name="frequency_interval"
              value="weekly"
              onChange={() => setFrequencyInterval('weekly')}
              checked={frequencyInterval === 'weekly'}
            /> Weekly<br/>
            <input
              type="radio"
              name="frequency_interval"
              value="monthly"
              disabled={true}
              onChange={() => setFrequencyInterval('monthly')}
              checked={frequencyInterval === 'monthly'}
            /> Monthly
          </div>}
          {recurring && <div className="column">
            <strong>For:</strong> (months)<br/>
            <input
              type="text"
              className="input"
              name="frequency_duration"
              value={frequencyDuration}
              onChange={e => setFrequencyDuration(e.target.value)}
            />
          </div>}
        </div>}

        <div className="columns">
          <div className="column">
            <div className="control">
              <TypeAhead
                className="input"
                name={PASS}
                placeholder="Passenger"
                value={passenger.fullName}
                onChange={(name, value) => setPassenger(value)}
                suggestion={suggestion}
                suggestions={users.filter(u => u.roles.includes('passenger'))}
                matchSuggestions={matchSuggestions}
              />
              {errors.includes('passenger') && <p className="help is-danger">Passenger is required</p>}
            </div>
          </div>
          <div className="column is-narrow">
            <div className="control">
              <a className="button" onClick={() => setNewPassenger(true) }>
                New
              </a>
            </div>
          </div>
        </div>

        {passenger.home_id && <div className="field">
          <label className="checkbox">
            <input type="checkbox" name="pickup" onChange={() => setDifferentPickup(!differentPickup)} checked={!differentPickup && passenger.home_id} /> Use passenger's address for pickup
          </label>
        </div>}

        {differentPickup && <div className="columns">
          <div className="column">
            <div className="control">
              <TypeAhead
                className="input"
                name="pickup"
                placeholder="Pick Up"
                value={pickup ? pickup.display : ''}
                onChange={(name, value) => setPickup(value)}
                suggestion={locationSuggestion}
                suggestions={locations}
                matchSuggestions={matchLocationSuggestions}
              />
              {errors.includes('pickup') && <p className="help is-danger">Pickup location is required</p>}
            </div>
          </div>
          <div className="column is-narrow">
            <div className="control">
              <a className="button" onClick={() => setNewPickup(true) }>
                New
              </a>
            </div>
          </div>
        </div>}

        <div className="columns">
          <div className="column">
            <div className="control">
              <TypeAhead
                className="input"
                name={DRIV}
                placeholder="Driver"
                value={driver ? driver.fullName : ''}
                onChange={(name, value) => setDriver(value)}
                suggestion={suggestion}
                suggestions={users.filter(u => u.roles.includes('driver'))}
                matchSuggestions={matchSuggestions}
              />
              {errors.includes('driver') && <p className="help is-danger">Driver is required</p>}
            </div>
          </div>
          <div className="column is-narrow">
            <div className="control">
              <a className="button" onClick={() => setNewDriver(true) }>
                New
              </a>
              {driver.id && <a className="button is-warning" style={{ margin: '0 0 0 5px' }} onClick={e => setDriver(new UserRecord())}>
                Clear
              </a>}
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="control">
              <TypeAhead
                className="input"
                name="dropoff"
                placeholder="Drop Off"
                value={dropoff ? dropoff.display : ''}
                onChange={(name, value) => setDropoff(value)}
                suggestion={locationSuggestion}
                suggestions={locations}
                matchSuggestions={matchLocationSuggestions}
              />
              {errors.includes('dropoff') && <p className="help is-danger">Dropoff location is required</p>}
            </div>
          </div>
          <div className="column is-narrow">
            <a className="button" onClick={() => setNewDropoff(true) }>
              New
            </a>
          </div>
        </div>

        <div className="field">
          <div className="control">
            <textarea
              className="textarea"
              name="note"
              onChange={e => setRideState(rideState.set('note', e.target.value))}
              placeholder="Notes"
              value={rideState.note}
            />
          </div>
        </div>

        {newPassenger && <NewUser
          user={new UserRecord({ roles: [PASS], organization_id: org.id })}
          dismiss={setNewPassenger.bind(null, false)}
          callback={setPassenger}
        />}

        {newDriver && <NewUser
          user={new UserRecord({ roles: [DRIV], organization_id: org.id })}
          dismiss={setNewDriver.bind(null, false)}
          callback={setDriver}
        />}

        {newDropoff && <NewAddress
          callback={setDropoff}
          address={new AddressRecord({ state: 'VT', organization_id: org.id })}
          dismiss={setNewDropoff.bind(null, false)}
        />}

        {newPickup && <NewAddress
          callback={setPickup}
          address={new AddressRecord({ state: 'VT', organization_id: org.id })}
          dismiss={setNewPickup.bind(null, false)}
        />}

      </section>
      <footer className="modal-card-foot">
        <button className="button" onClick={() => closeModal()}>Close</button>
        {(!rideState.canceled && rideState.id) && <button className="button is-danger" onClick={cancel}>Cancel Ride</button>}
        {rideState.canceled && <button className="button is-primary" onClick={activate}>Activate Ride</button>}
        <button className="button is-success" onClick={save}>Save changes</button>
      </footer>
    </div>
  </div>
}
