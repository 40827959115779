import React from 'react';
import { useSelector } from 'react-redux';

export default function Footer() {
  const user = useSelector(state => state.users.get('user'));

  if (!user.id) return <></>;

  return <footer className="footer">
    <div className="has-text-centered">
      <span className="icon is-large">
        <i className="fa fa-car"></i>&nbsp;
        <i className="fa fa-2x fa-car"></i>&nbsp;
        <i className="fa fa-car"></i>
      </span>
    </div>
    <div className="has-text-centered">
      <div className="has-text-weight-semibold">powered by Rides n' Rides n' Rides</div>
      <small>© 2021 ridesnridesnrides.com</small>
    </div>
  </footer>
}
