import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrderedMap } from 'immutable';
import moment from 'moment';
import classNames from 'classnames';
import { sorted, time_convert } from '../../lib/utils';
import { getRide, getRides, updateRide } from '../../actions/rides';
import Options from './Options';
import Mobile from './schedule/Mobile';
import Desktop from './schedule/Desktop';
import Claim from './Claim';

export default function Schedule({ match, all, mine }) {
  const dispatch = useDispatch();
  const rides = useSelector(state => state.entities.get('ride'));
  const user = useSelector(state => state.users.get('user'));
  const users = useSelector(state => state.entities.get('user'));
  const locations = useSelector(state => state.entities.get('location'));
  const org = useSelector(state => state.entities.get('organization').get(user.organization_id));
  const loading = useSelector(state => state.rides.get('loading'));

  const [cancelable, setCancelable] = useState(true);
  const [rideData, setRideData] = useState(new OrderedMap());
  const [claiming, setClaiming] = useState(false);

  useEffect(() => {
    dispatch(getRides())
      .then(() => {
        if (match && match.params.id) {
          const ride = rides.get(match.params.id);
          //ride && dispatch(Claim(ride));
        }
      });

    setInterval(() => dispatch(getRides()), 60000);
  }, []);

  useEffect(() => {
    if (rides.count() >= 1) {
      let d = rides
        .filter(r => all || (mine ? r.driver_id === user.id : !r.driver_id && user.availability.includes(moment(r.when).format('dddd').toLowerCase())))
        .valueSeq();

      setRideData(d);
    }
  }, [rides]);

  const cancel = ride => {
    if (!ride.cancelable) {
      setCancelable(false)
      return setTimeout(() => setCancelable(true), 10000);
    }

    dispatch(updateRide(
      ride,
      ride.canceled,
      null,
      users.get(ride.passenger_id),
      locations.get(ride.pickup_id),
      locations.get(ride.dropoff_id)
    ))
    .then(dispatch(getRides()));
  }

  if (rides.get('loading')) return <progress className="progress is-small is-primary" max="100">15%</progress>

  return (
    <div>
      {!cancelable && <div className="notification is-danger">
        Rides cannot be canceled within 24 hours of the pickup.
        <p>If you need to cancel this ride, please contact your site administrator.</p>
      </div>}

      {loading && <progress className="progress is-small is-primary" max="100">15%</progress>}

      <Desktop
        rides={rideData}
        claim={setClaiming}
        cancel={cancel}
      />

      {claiming && <Claim
        ride={claiming}
        dismiss={setClaiming.bind(null, false)}
      />}
    </div>
  );
}
